import styles from "./StatusProgress.module.css"; // Import your CSS module here
import hrLineIcon from "../../../../../assets/icons/hrLine.png";
import { useEffect, useState } from "react";
import ProgressModal from "../../../../../utils/Modals/ProgressModal/ProgressModal";
import ProfileIcon from "../../../../../assets/svgicons/ProfileIcon";
import VRIconBlack from "../../../../../assets/svgicons/VRIconBlack";
import ExpandIcon from "../../../../../assets/svgicons/ExpandIcon";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { text } from "../../../../../constant/text";
import { lang } from "../../../../../constant/Var";

const StatusProgress = ({ loading, participantStatus, activeIcon }) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 769);
  const [inProgressStatus, setInProgressStatus] = useState(2);

  useEffect(() => {
    const checkScreenSize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", checkScreenSize);

    return () => window.removeEventListener("resize", checkScreenSize);
  }, []);

  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    if (participantStatus) {
      if (
        !participantStatus["Profile Information"] &&
        !participantStatus["Diagnostics Completed"] &&
        !participantStatus["Experience Completed"]
      )
        setInProgressStatus(2);
      else if (
        participantStatus["Profile Information"] &&
        !participantStatus["Diagnostics Completed"] &&
        !participantStatus["Experience Completed"]
      )
        setInProgressStatus(3);
      else if (
        participantStatus["Profile Information"] &&
        participantStatus["Diagnostics Completed"] &&
        !participantStatus["Experience Completed"]
      )
        setInProgressStatus(4);
      else setInProgressStatus(0);
    }
  }, [participantStatus, activeIcon]);
  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const renderDropdown = () => (
    <div className={styles.dropdownContainer}>
      <div className={styles.dropdownHeader}>
        {/* Change text based on the dropdown state */}
        <div
          style={{
            background: "#ebc03f",
            paddingTop: "5px",
            width: "42px",
            borderRadius: "5px 0px 0px 5px",
            alignItems: "center",
          }}
        >
          {inProgressStatus > 2 ? (
            <VRIconBlack className={styles.backgroundForProfileIconImage} />
          ) : (
            <ProfileIcon className={styles.backgroundForProfileIconImage} />
          )}
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          {inProgressStatus ? (
            <div className={styles.inprogress}>
              <span className={styles.inProgresslabelText}>
                {text?.numbers[inProgressStatus]?.[lang]}
              </span>
            </div>
          ) : (
            <CheckCircleIcon
              className={styles.statusIcon}
              sx={{ fontSize: "24px", color: "#016f4a" }}
            />
          )}
          <div>
            <span
              className={
                inProgressStatus ? styles.inprogressText : styles.verified
              }
            >
              {inProgressStatus === 2
                ? text?.profile_information[lang]
                : inProgressStatus === 3
                ? text?.status["Diagnostics Completed"]?.[lang]
                : text?.status["Experience Completed"]?.[lang]}
            </span>
          </div>
        </div>
        <div className={styles.dropdownArrow} onClick={toggleModal}>
          <ExpandIcon />
        </div>
      </div>
      <ProgressModal
        isOpen={isModalOpen}
        onClose={toggleModal}
        participantStatus={participantStatus}
        inProgressStatus={inProgressStatus}
      />
    </div>
  );

  const renderProgressContainer = () => (
    <>
      {participantStatus["Mobile Number Verified"] ? (
        <div className={styles.progessContainer}>
          {/* currentStep -1  */}
          <div className={styles.currentStepOne}>
            <div className={styles.iconsContainer}>
              <div className={styles.backgroundForProfileIcon}>
                <ProfileIcon className={styles.iconStyles} />
              </div>
              <div className={styles.statusCheckcontainer}>
                {/* Group 1: Mobile Number Verified */}
                <div className={styles.statusGroup}>
                  {participantStatus["Mobile Number Verified"] ? (
                    <CheckCircleIcon
                      className={styles.statusIcon}
                      sx={{ fontSize: "24px", color: "#016f4a" }}
                    />
                  ) : (
                    <div className={styles.inprogress}>
                      <span className={styles.inProgresslabelText}>
                        {text?.numbers["1"]?.[lang]}
                      </span>
                    </div>
                  )}
                  <span className={styles.verified}>
                    {text?.status["Mobile Number Verified"]?.[lang]}
                  </span>
                </div>

                {/* Separator Line */}
                <img src={hrLineIcon} alt="hr line" className={styles.hrLine} />

                {/* Group 2: Profile Information */}
                <div className={styles.statusGroup}>
                  {participantStatus["Profile Information"] ? (
                    <CheckCircleIcon
                      className={styles.statusIcon}
                      sx={{ fontSize: "24px", color: "#016f4a" }}
                    />
                  ) : (
                    <div
                      className={
                        inProgressStatus === 2
                          ? styles.inprogress
                          : styles.untouchedProgressIcon
                      }
                    >
                      <span
                        className={
                          inProgressStatus === 2
                            ? styles.inProgresslabelText
                            : styles.unTouchedLabelText
                        }
                      >
                        {text?.numbers["2"]?.[lang]}
                      </span>
                    </div>
                  )}
                  <span
                    className={
                      participantStatus["Profile Information"]
                        ? styles.verified
                        : inProgressStatus === 2
                        ? styles.inprogressText
                        : styles.unTouchedText
                    }
                  >
                    {text?.status["Profile Completed"]?.[lang]}
                  </span>
                </div>
              </div>
            </div>
          </div>

          {/* currentStep -2  */}
          <div className={styles.currentStepTwo} style={{ maxHeight: "58px" }}>
            <div className={styles.iconsContainer}>
              <div className={styles.backgroundForProfileIcon}>
                <VRIconBlack
                  className={styles.backgroundForProfileIconImageVr}
                />
              </div>
              <div className={styles.statusCheckcontainer}>
                {/* Group 3: Diagnostics Completed */}
                <div className={styles.statusGroup}>
                  {participantStatus["Diagnostics Completed"] ? (
                    <CheckCircleIcon
                      className={styles.statusIcon}
                      sx={{ fontSize: "24px", color: "#016f4a" }}
                    />
                  ) : (
                    <div
                      className={
                        inProgressStatus === 3
                          ? styles.inprogress
                          : styles.untouchedProgressIcon
                      }
                    >
                      <span
                        className={
                          inProgressStatus === 3
                            ? styles.inProgresslabelText
                            : styles.unTouchedLabelText
                        }
                      >
                        {text?.numbers["3"]?.[lang]}
                      </span>
                    </div>
                  )}

                  <span
                    className={
                      participantStatus["Diagnostics Completed"]
                        ? styles.verified
                        : inProgressStatus === 3
                        ? styles.inprogressText
                        : styles.unTouchedText
                    }
                  >
                    {text?.status["Diagnostics Completed"]?.[lang]}
                  </span>
                </div>

                {/* Separator Line */}
                <img src={hrLineIcon} alt="hr line" className={styles.hrLine} />

                {/* Group 4: Experience Completed */}
                <div className={styles.statusGroup}>
                  {participantStatus["Experience Completed"] ? (
                    <CheckCircleIcon
                      className={styles.statusIcon}
                      sx={{ fontSize: "24px", color: "#016f4a" }}
                    />
                  ) : (
                    <div
                      className={
                        inProgressStatus === 4
                          ? styles.inprogress
                          : styles.untouchedProgressIcon
                      }
                    >
                      <span
                        className={
                          inProgressStatus === 4
                            ? styles.inProgresslabelText
                            : styles.unTouchedLabelText
                        }
                      >
                        {text?.numbers["4"]?.[lang]}
                      </span>
                    </div>
                  )}

                  <span
                    className={
                      participantStatus["Experience Completed"]
                        ? styles.verified
                        : inProgressStatus === 4
                        ? styles.inprogressText
                        : styles.unTouchedText
                    }
                  >
                    {text?.status["Experience Completed"]?.[lang]}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );

  return (
    <div className={styles.progressHeader}>
      <h2 className={styles.statusTitle}>{text?.current_status[lang]}</h2>
      {!loading && (isMobile ? renderDropdown() : renderProgressContainer())}
    </div>
  );
};

export default StatusProgress;
