import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import styles from "./AdminSimulations.module.css";
import AdminNav from "../AdminReusableComponent/AdminNav/AdminNav";
import SimulationControls from "./SimulationControl";
import { SimulationContext } from "../../../context/SimulationCotext";
import {
  simulationModule45,
  simulationStart,
  simulationTiran,
} from "../../apiRoutes/simulationApi/simulation-api";
import { format } from "date-fns";
import { useAuth } from "../../../context/AuthContext";
import { toast } from "react-toastify";
import SimulationNavigation from "./SimulationNavigation/SimulationNavigation";
import SimulationRecording from "./SimulationRecording/SimulationRecording";
import { text } from "../../../constant/text";
import { lang, refershTokenTime } from "../../../constant/Var";
import { refreshToken } from "../../apiRoutes/participantApi/participant-api";
import Banner from "../../../sharedComponents/Banner/Banner";

const AdminSimulationsScreen = () => {
  const [selected3DWorld, setSelected3DWorld] = useState("");
  const [selectedParameters, setSelectedParameters] = useState([]);
  const [selectedAreas, setSelectedAreas] = useState([]);
  const [selectedPOIs, setSelectedPOIs] = useState([]);
  const [selectedPOI, setSelectedPOI] = useState("");
  const [selectedFloorMaterials, setSelectedFloorMaterials] = useState([]);
  const [selectedSurroundingMaterials, setSelectedSurroundingMaterials] =
    useState([]);
  const [selectedTopMaterials, setSelectedTopMaterials] = useState([]);
  const [isNextButtonEnabled, setIsNextButtonEnabled] = useState(false);
  const { simulationState, updateSimulationState } =
    useContext(SimulationContext);
  const [networkError, setNetworkError] = useState(false);

  const { authData, signOut, setAuth } = useAuth();
  const accessToken = authData?.data?.tokenresponse?.AccessToken;
  const [activeOption, setActiveOption] = useState("option1");

  // Refresh token useEffect
  useEffect(() => {
    const config = {
      method: "post",
      url: refreshToken,
      headers: {
        refresh_token: authData.data.tokenresponse.RefreshToken,
      },
    };
    const refreshAuthToken = async () => {
      try {
        const response = await axios(config);
        if (response.data && response.data.success) {
          const updatedAuthData = {
            ...authData,
            data: {
              ...authData.data,
              tokenresponse: {
                ...authData.data.tokenresponse,
                AccessToken: response.data.data.AccessToken,
                IdToken: response.data.data.IdToken,
              },
            },
          };
          setAuth(updatedAuthData);
        } else {
          console.error("Failed to refresh token:", response.data);
        }
      } catch (error) {
        if (
          error.response?.data?.message.includes("Failed to generate token")
        ) {
          signOut();
        } else {       
          if (error.message.includes("Network Error")) {
            console.log("network error", error)
            // setNetworkError(true)
          } else{
            console.error("Error refreshing token:", error);          
          }  
        }
      }
    };

    refreshAuthToken();
    const intervalId = setInterval(refreshAuthToken, refershTokenTime);
    return () => clearInterval(intervalId);
  }, [activeOption]);

  const dropdown3DWorld = [
    { value: "module45", label: "Module 45" },
    { value: "tiran", label: "Tiran" },
  ];

  const handle3DWorldChange = (selectedOption) => {
    if (selectedOption) {
      setSelected3DWorld(selectedOption.value);
      const url =
        selectedOption.value === "module45"
          ? simulationModule45
          : simulationTiran;

      const config = {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      };

      axios
        .get(url, config)
        .then((response) => {
          const data = response.data.data;
          const parametersFromAPI = data.parameters.map(
            (p) => p.charAt(0).toUpperCase() + p.slice(1)
          );
          const areasFromAPI = data.areas.map((area) => ({
            value: area,
            label: area,
          }));
          const floorMaterialsFromAPI = data.floor_materials.map(
            (floorMaterial) => ({ value: floorMaterial, label: floorMaterial })
          );
          const surroundingMaterialsFromAPI = data.surrounding_materials.map(
            (surroundingMaterial) => ({
              value: surroundingMaterial,
              label: surroundingMaterial,
            })
          );
          const topMaterialsFromAPI = data.top_materials.map((topMaterial) => ({
            value: topMaterial,
            label: topMaterial,
          }));
          const POIsfromAPI = data.POI.map((POI) => ({
            value: POI,
            label: POI,
          }));
          // Update state with API response
          setSelectedPOIs(POIsfromAPI);
          setSelectedParameters(parametersFromAPI);
          setSelectedAreas(areasFromAPI);
          setSelectedFloorMaterials(floorMaterialsFromAPI);
          setSelectedSurroundingMaterials(surroundingMaterialsFromAPI);
          setSelectedTopMaterials(topMaterialsFromAPI);
          setIsNextButtonEnabled(true);
        })
        .catch((error) => {
          if (error.message.includes("Network Error")) {
            console.log("network error", error)
            setNetworkError(true)
          } else{
            console.error("Error fetching data:", error);
          }  
        });
    } else {
      // Reset or handle other 3D World selections
      setSelectedParameters([]);
      setSelectedAreas([]);
      setSelectedFloorMaterials([]);
      setSelectedSurroundingMaterials([]);
      setSelectedTopMaterials([]);
      setIsNextButtonEnabled(false);
    }
  };

  useEffect(() => {
    const is3DWorldSelected = !!selected3DWorld;
    const isParametersSelected = selectedParameters.length > 0;
    const isAreaSelected = selectedAreas.length > 0;
    const isTotalNPCsProvided = simulationState.totalNPCs > 0;
  
    const isStartDateProvided = !!simulationState.simulationDuration?.startDate;
    const isEndDateProvided = !!simulationState.simulationDuration?.endDate;
  
    const isMaleGroupSelected = simulationState.ageGroupSelections?.male?.length > 0;
    const isFemaleGroupSelected = simulationState.ageGroupSelections?.female?.length > 0;
    const isNonBinaryGroupSelected = simulationState.ageGroupSelections?.nonBinary?.length > 0;
  
    setIsNextButtonEnabled(
      is3DWorldSelected &&
      isParametersSelected &&
      isAreaSelected &&
      isTotalNPCsProvided &&
      isStartDateProvided &&
      isEndDateProvided &&
      (isMaleGroupSelected || isFemaleGroupSelected || isNonBinaryGroupSelected)
    );
  }, [
    selected3DWorld,
    selectedParameters,
    selectedAreas,
    simulationState.totalNPCs,
    simulationState.simulationDuration.startDate,
    simulationState.simulationDuration.endDate,
    simulationState.ageGroupSelections.male,
    simulationState.ageGroupSelections.female,
    simulationState.ageGroupSelections.nonBinary,
  ]);

  
  const handleCheckBox = (filterValue) => {
    const isSelected = selectedParameters.includes(filterValue);
    const updatedParameters = isSelected
      ? selectedParameters.filter((f) => f !== filterValue)
      : [...selectedParameters, filterValue];
    setSelectedParameters(updatedParameters);
  };

  const handleNext = () => {
    const url = simulationStart;
    const simulationDuration = simulationState.simulationDuration;
    const simulationAge = simulationState.ageGroupSelections;
    const typeObj = {
      modelType: selected3DWorld,
      parameters: selectedParameters,
      areas: selectedAreas,
      materialSurroundings: selectedSurroundingMaterials,
      materialTop: selectedTopMaterials,
      materialFloor: selectedFloorMaterials,
    };
    updateSimulationState({ ...simulationState, simulationType: typeObj });
    console.log(simulationState, "pppp");
    const requestBody = {
      modeltype: selected3DWorld,
      startdate: format(new Date(simulationDuration.startDate), "dd/MM/yyyy"),
      enddate: format(new Date(simulationDuration.endDate), "dd/MM/yyyy"),
      totalNPC: simulationState.totalNPCs,
      Males: simulationAge.male
        ? simulationAge.male.map((item) => item.value)
        : [],
      Females: simulationAge.female
        ? simulationAge.female.map((item) => item.value)
        : [],
      NonBinary: simulationAge.nonBinary
        ? simulationAge.nonBinary.map((item) => item.value)
        : [],
      parameters: selectedParameters,
    };
    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };
    console.log(requestBody);
    axios
      .post(url, requestBody, config)
      .then(() => {
        toast.success("Simulation started successfully.");
      })
      .catch((error) => {
        if (error.message.includes("Network Error")) {
          console.log("network error", error)
          setNetworkError(true)
        } else{
          toast.error("Failed to start Recording");
          console.error("Error starting simulation:", error);
        }  
      });
  };

  const handlePOIChange = (selectedOption) => {
    if (selectedOption.value) {
      setSelectedPOI(selectedOption.value); // Store the selected area object
    }
  };

  const handleRefresh = () => {
    window.location.reload();
  };

  return (
    <>
      {networkError && <Banner message={text?.validations?.something_went_wrong_refresh[lang]} onRefresh={handleRefresh} action={text?.refresh[lang]}/>}
      <AdminNav />
      <div className={styles.container}>
        <div className={styles.row}>
          <SimulationNavigation
            activeOption={activeOption}
            setActiveOption={setActiveOption}
          />
          {activeOption === "option1" && (
            <div className={styles.formContainer}>
              <SimulationControls
                selected3DWorld={selected3DWorld}
                selectedParameters={selectedParameters}
                selectedPOI={selectedPOI}
                selectedPOIs={selectedPOIs}
                handleCheckBox={handleCheckBox}
                dropdown3DWorld={dropdown3DWorld}
                handle3DWorldChange={handle3DWorldChange}
                selectedAreas={selectedAreas}
                selectedSurroundingMaterials={selectedSurroundingMaterials}
                selectedTopMaterials={selectedTopMaterials}
                selectedFloorMaterials={selectedFloorMaterials}
                isNextButtonEnabled={isNextButtonEnabled}
                handlePOIChange={handlePOIChange}
                styles={styles}
              />
              <div className={styles.footerBtnContainer}>          
                <button
                  type="button"
                  onClick={handleNext}
                  className={`${styles.nextBtn} ${
                    isNextButtonEnabled ? styles.nextBtnEnable : ""
                  }`}
                  disabled={!isNextButtonEnabled}
                >
                  {text?.simulations?.submit[lang]}
                </button>
              </div>
            </div>
          )}
          {activeOption === "option2" && (
            <div className={styles.formContainer}>
              <SimulationRecording dropdown3DWorld={dropdown3DWorld} setNetworkError={setNetworkError} />
            </div>
          )}

          {activeOption === "option3" && (
            <div className={styles.formContainer}></div>
          )}
        </div>
      </div>
    </>
  );
};

export default AdminSimulationsScreen;
