import React, { useState } from "react";
import styles from "./RDPNavigation.module.css"; 
import AdminLogoutModal from "../../../utils/Modals/AdminModal/AdminLogoutModal/AdminLogoutModal";
import CreateParticipantModal from "../../../utils/Modals/ParticipantModal/CreateParticipantModal/CreateParticipantModal";
import constants from "../../../constants";
import { rdpCreateParticipant } from "../../apiRoutes/rdpApi/rdp-api";
import axios from "axios";
import { useRDP } from "../../../context/rdpContext";
import RdpSearchModal from "../../../utils/Modals/AdminModal/SearchModal/RdpSearchModal";
import RdpFilterModal from "../../../utils/Modals/AdminModal/FilterModal/RdpFilterModal";
import SearchIcon from "../../../assets/svgicons/SearchIcon";
import FilterIcon from "../../../assets/svgicons/FilterIcon";
import LogoutIcon from "../../../assets/svgicons/LogoutIcon";
import { useAuth } from "../../../context/AuthContext"
import { text } from "../../../constant/text.js";
import { lang } from "../../../constant/Var.js";

const RDPNavigation = ({
  activeIcon,
  handleLogoutClick,
  onLogoutInitiated,
  handleModalConfirm,
  toggleMenu = () => {},
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCreateUserModalOpen, setIsCreateUserModalOpen] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");
  const [isResponseSuccess, setIsResponseSuccess] = useState(true);
  const { triggerTableUpdate } = useRDP();
  const [showSearchModal, setShowSearchModal] = useState(false);
  const [showFilterModal, setShowFilterhModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const { authData, signOut } = useAuth();

  const showModal = () => {
    setIsModalOpen(true);
    onLogoutInitiated(true);
  };

  const hideModal = () => {
    setIsModalOpen(false);
    onLogoutInitiated(false);
  };
  const confirmLogout = (e) => {
    handleModalConfirm();
    console.log("Logout confirmed"); // Debugging log
    hideModal(); // Close the modal
    handleLogoutClick(); // Initiate logout action
  };

  const handleModalCancel = () => {
    setIsCreateUserModalOpen(false);
  };

  const handleCreateParticipantClick = () => {
    setIsCreateUserModalOpen(true);
  };

  //RDP MODAL CONFIRM
  const handleRdpModalConfirm = async (username, fullPhoneNumber) => {
    const idToken =
      authData?.data?.tokenresponse?.IdToken ||
      localStorage.getItem("id_token");
    const accessToken =
      authData?.data?.tokenresponse?.AccessToken ||
      localStorage.getItem("access_token");

    if (!accessToken || !idToken) {
      console.error("Tokens are not available");
      return;
    }

    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        id_token: idToken,
      },
    };

    const requestBody = {
      name: username,
    };
    if (fullPhoneNumber) {
      requestBody["phone_number"] = fullPhoneNumber;
    }

    try {
      const response = await axios.post(rdpCreateParticipant, requestBody, config);
      console.log("Participant created successfully:", response.data);
      setResponseMessage(text?.participant_add_success[lang]); // Set success message
      setIsResponseSuccess(true);
      triggerTableUpdate();
    } catch (error) {
      let errorMessage = text?.validations.failed_to_add_participant[lang] // Default error message
      if (error.response) {
        console.error(error);
        if (
          error.response?.data?.message ===
            constants.USER_ACCOUNT_ALREADY_EXISTS ||
          error.response?.data?.message ===
            constants.USER_ACCOUNT_ALREADY_EXISTS_AND_EXPIRED
        ) {
          errorMessage = text?.validations.number_already_exists[lang];
        } else if (
          error.response.data.message.includes(
            constants.ACCESS_TOKEN_IS_INVALID
          )
        ) {
          signOut();
        } else {
          errorMessage = `${
            error.response.data.Error || error.response.data.message
          }`;
        }
        setResponseMessage(errorMessage); // Set error message
        setIsResponseSuccess(false);
      } else if (error.message.includes("Network Error")) {
        errorMessage = text?.validations.something_went_wrong_refresh[lang];
        setResponseMessage(errorMessage);
        setIsResponseSuccess(false);
      }
    }
  };

  //SEARCH MODAL
  const handleSearchClick = () => {
    setShowSearchModal(true); // Show the search modal
  };

  const closeSearchModal = () => {
    setShowSearchModal(false); // Close the search modal
  };

  const handleFilterClick = (event) => {
    setAnchorEl(event.currentTarget);
    setShowFilterhModal(true);
  };

  const closeFilterModal = () => {
    setShowFilterhModal(false);
  };

  return (
    <div className={styles.statusSection}>
      <div>
        <button
          className={styles.createUserButton}
          onClick={() => {
            toggleMenu();
            handleCreateParticipantClick();
          }}
        >
          {text?.add_participant[lang]}
        </button>
        <div
          className={styles.iconWithLabel}
          onClick={() => {
            toggleMenu();
            handleSearchClick();
          }}
        >
          <SearchIcon styles={styles} />
          <span
            className={
              activeIcon === "search"
                ? styles.activeIconLabel
                : styles.iconLabel
            }
          >
            {text?.search[lang]}
          </span>
        </div>
        <RdpSearchModal isOpen={showSearchModal} onClose={closeSearchModal} />

        <div
          className={styles.iconWithLabel}
          onClick={(event) => {
            toggleMenu();
            handleFilterClick(event);
          }}
        >
          <FilterIcon styles={styles} />
          <span
            className={
              activeIcon === "filter"
                ? styles.activeIconLabel
                : styles.iconLabel
            }
          >
            {text?.filter_by[lang]}
          </span>
        </div>
        <RdpFilterModal
          isOpen={showFilterModal}
          onClose={closeFilterModal}
          anchorEl={anchorEl}
        />
      </div>
      <div
        className={`${styles.iconWithLabel} ${styles.logOutIcon}`}
        onClick={() => {
          toggleMenu();
          showModal();
        }}
      >
        <LogoutIcon styles={styles} />
        <span className={styles.iconLabel}>{text?.logout[lang]}</span>
      </div>
      <AdminLogoutModal
        isOpen={isModalOpen}
        onCancel={hideModal}
        onConfirm={confirmLogout}
      />

      <CreateParticipantModal
        isOpen={isCreateUserModalOpen}
        onConfirm={handleRdpModalConfirm}
        onCancel={handleModalCancel}
        responseMessage={responseMessage}
        isResponseSuccess={isResponseSuccess}
        setResponseMessage={setResponseMessage}
      />
    </div>
  );
};

export default RDPNavigation;
