import styles from "./AdminSigninMessage.module.css";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  ADMIN_DASHBOARD,
  ADMIN_SIMULATIONS,
} from "../../apiRoutes/adminRoutes/adminRoutes";
import { useAuth } from "../../../context/AuthContext";
import constants from "../../../constants";
import { text } from "../../../constant/text";
import { lang } from "../../../constant/Var";
import GreenTick from "../../../assets/svgicons/GreenTick";

const AdminSigninMessage = () => {
  const [message, setMessage] = useState("");
  const navigate = useNavigate();
  const {
    setAdminLoginSuccess,
    adminAuthData,
    setAdminInfo,
  } = useAuth();

  const token_payload = adminAuthData.data.tokenresponse.IdToken.split(".")[1];

  // Replace base64url characters with base64 characters
  const base64 = token_payload.replace(/-/g, "+").replace(/_/g, "/");

  // Pad the base64 string if necessary
  const paddingNeeded = (4 - (base64.length % 4)) % 4;
  const paddedBase64 = base64 + "=".repeat(paddingNeeded);

  // Decode and parse the JSON
  let decoded_token;
  try {
    var decodedPayload = atob(paddedBase64);
    decoded_token = JSON.parse(decodedPayload);
    console.log(decoded_token);
  } catch (error) {
    console.error("Error decoding token:", error);
  }

  const preventBack = () => {
    window.history.pushState(null, "", window.location.href);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setAdminInfo({
        username:
          decoded_token["name"]?.charAt(0).toUpperCase() +
          decoded_token["name"]?.slice(1),
        role: decoded_token["custom:role"],
      });
      setAdminLoginSuccess(true);
      if (decoded_token["custom:role"] === constants.URBAN_PLANNER) {
        navigate(ADMIN_SIMULATIONS);
      } else {
        navigate(ADMIN_DASHBOARD);
      }
    }, 3000);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {}, []);

  useEffect(() => {
    window.history.pushState(null, "", window.location.href);
    window.addEventListener("popstate", preventBack);

    return () => {
      window.removeEventListener("popstate", preventBack);
    };
  }, []);

  useEffect(() => {
    setMessage(text?.sign_in_successful[lang]);
  }, []);

  return (
    <div className={styles.participantProfileCreation}>
      <div className={styles.profileContainer}>
        <div className={styles.profileCreation}>
          <GreenTick/>
        </div>
        <h1 className={styles.profileText}>{message}</h1>
        <div className={styles.buttonContainer}></div>
      </div>
    </div>
  );
};

export default AdminSigninMessage;