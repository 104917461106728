import React from "react";

const CircularUnchecked = () => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="7"
      cy="7"
      r="6.5"
      transform="matrix(1 0 0 -1 0 14)"
      stroke="#E5E5E5"
    />
  </svg>
);

export default CircularUnchecked;
