import React from "react";

const CircularYellowTick = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M10 1.25C12.3206 1.25 14.5462 2.17187 16.1872 3.81282C17.8281 5.45376 18.75 7.67936 18.75 10C18.75 12.3206 17.8281 14.5462 16.1872 16.1872C14.5462 17.8281 12.3206 18.75 10 18.75C7.67936 18.75 5.45376 17.8281 3.81282 16.1872C2.17187 14.5462 1.25 12.3206 1.25 10C1.25 7.67936 2.17187 5.45376 3.81282 3.81282C5.45376 2.17187 7.67936 1.25 10 1.25ZM8.91 11.7262L6.96625 9.78125C6.89657 9.71157 6.81384 9.65629 6.7228 9.61858C6.63175 9.58087 6.53417 9.56146 6.43563 9.56146C6.33708 9.56146 6.2395 9.58087 6.14845 9.61858C6.05741 9.65629 5.97468 9.71157 5.905 9.78125C5.76427 9.92198 5.68521 10.1129 5.68521 10.3119C5.68521 10.5109 5.76427 10.7018 5.905 10.8425L8.38 13.3175C8.44948 13.3875 8.53214 13.4431 8.62322 13.4811C8.71429 13.519 8.81197 13.5385 8.91062 13.5385C9.00928 13.5385 9.10696 13.519 9.19803 13.4811C9.28911 13.4431 9.37177 13.3875 9.44125 13.3175L14.5662 8.19125C14.6369 8.12186 14.693 8.03917 14.7315 7.94796C14.77 7.85674 14.7901 7.75881 14.7906 7.65981C14.791 7.5608 14.7719 7.46269 14.7342 7.37112C14.6966 7.27956 14.6412 7.19635 14.5712 7.1263C14.5012 7.05625 14.4181 7.00075 14.3266 6.963C14.2351 6.92524 14.137 6.90598 14.038 6.90632C13.939 6.90667 13.841 6.92661 13.7497 6.965C13.6585 7.00339 13.5757 7.05947 13.5063 7.13L8.91 11.7262Z"
      fill="#EBC03F"
    />
  </svg>
);

export default CircularYellowTick;
