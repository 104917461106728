// ParticipantLayout.js
import React, { useEffect } from "react";
import styles from "../AdminLogin/AdminLogin.module.css";
import { Outlet, useNavigate } from "react-router-dom";
import { useAuth } from "../../../context/AuthContext";
import {
  ADMIN_DASHBOARD,
  ADMIN_SIMULATIONS,
} from "../../apiRoutes/adminRoutes/adminRoutes";
import { RDP_DASHBOARD } from "../../apiRoutes/rdpParticipantRoutes/rdpParticipantRoutes";
import { PARTICIPANT_PROFILE_VIEW } from "../../apiRoutes/participantRoutes/participantsRoutes";
import constants from "../../../constants";
import { useImageAssets } from "../../../context/ImageAssetsContext";

const AdminLayout = () => {
  const navigate = useNavigate();
  const { loggedIn, role, isAdminLoginSuccessful, adminInfo } =
    useAuth();
    const {imageAssetsData,loading} = useImageAssets();

  useEffect(() => {
    if (loggedIn) {
      if (role === constants.RDP) {
        navigate(RDP_DASHBOARD);
      } else if (role === constants.PARTICIPANT) {
        navigate(PARTICIPANT_PROFILE_VIEW);
      } else if (adminInfo.role === "Admin") {
        navigate(ADMIN_DASHBOARD);
      } else if (adminInfo.role === constants.URBAN_PLANNER) {
        navigate(ADMIN_SIMULATIONS);
      }
    }
  }, []);

  useEffect(() => {
    if (isAdminLoginSuccessful) {
      if (adminInfo.role === "Admin") {
        navigate(ADMIN_DASHBOARD);
      } else if (adminInfo.role === constants.URBAN_PLANNER) {
        navigate(ADMIN_SIMULATIONS);
      }
    }
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.imageSection}
      style={{backgroundImage:`url(${imageAssetsData['login image.png']})`,
      backgroundColor: '#fff'
      }}>
        <img
          src={imageAssetsData['neom logo.png']}
          alt="Neom Logo"
          className={styles.logo}
          draggable="false"
        />
      </div>
      <Outlet />
      {/* This will render the specific component based on the route */}
    </div>
  );
};

export default AdminLayout;
