import React from "react";

const VRIconYellow = ({ className }) => (
  <svg
    width="25"
    height="19"
    viewBox="0 0 25 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M12.5 1.95C15.4015 1.95 18.0093 2.95156 19.7656 4.75313C21.2102 4.94285 22.573 5.53277 23.7 6.45625C21.7297 2.31562 17.6484 0 12.5 0C7.42185 0 3.31248 2.3125 1.31873 6.44063C2.3519 5.59924 3.5831 5.03608 4.89529 4.80469C6.61873 2.925 9.33435 1.95 12.5 1.95Z"
      fill="#EBC03F"
    />
    <path
      d="M18.75 18.75C17.1246 18.7499 15.5631 18.1166 14.3969 16.9844L14.3938 16.9812C14.2592 16.88 14.1147 16.7925 13.9625 16.7203C13.504 16.5095 13.0046 16.4023 12.5 16.4062C11.8531 16.4062 11.3609 16.5672 11.0391 16.7203C10.8863 16.7924 10.7413 16.8799 10.6063 16.9812L10.6031 16.9844C9.86846 17.6976 8.96983 18.2194 7.98622 18.5039C7.00262 18.7884 5.96419 18.8269 4.96223 18.616C3.96027 18.4051 3.02547 17.9512 2.24003 17.2943C1.45459 16.6374 0.842563 15.7976 0.457769 14.8488C0.0729745 13.8999 -0.0728037 12.871 0.0332523 11.8526C0.139308 10.8342 0.49395 9.85746 1.06599 9.00824C1.63804 8.15902 2.40996 7.46335 3.3139 6.9824C4.21783 6.50144 5.22608 6.24994 6.25 6.25H18.75C20.4076 6.25 21.9973 6.90848 23.1694 8.08058C24.3415 9.25268 25 10.8424 25 12.5C25 14.1576 24.3415 15.7473 23.1694 16.9194C21.9973 18.0915 20.4076 18.75 18.75 18.75Z"
      fill="#EBC03F"
    />
  </svg>
);

export default VRIconYellow;
