import React from "react";

const VisualizationIcon = ({ styles }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    style={styles}
  >
    <path
      d="M9.99983 0.314245C8.74268 0.268386 7.76917 1.13698 6.71499 1.68276C5.2812 2.53268 3.80476 3.31674 2.39952 4.21073C1.48038 4.9578 1.05808 6.15995 1.1546 7.32085C1.15995 9.37913 1.14393 11.4379 1.16268 13.4958C1.28171 14.7419 2.14577 15.7995 3.25765 16.3208C5.04257 17.3452 6.81702 18.3887 8.60847 19.4011C9.74729 19.9204 11.0955 19.702 12.1029 18.9994C13.8826 17.9657 15.6733 16.9502 17.446 15.9049C18.4657 15.1789 18.9498 13.9019 18.8451 12.6784C18.8398 10.6201 18.8558 8.56135 18.837 6.50338C18.7179 5.25737 17.854 4.1996 16.742 3.67854C14.957 2.65432 13.1823 1.61155 11.3909 0.598971C10.9573 0.397565 10.4757 0.315573 9.99987 0.314206M8.87897 5.49741H11.1212V14.5021H8.87897V5.49741ZM11.7005 7.45733H13.9416V14.5021H11.7005V7.45733ZM6.05784 11.1323H8.29964V14.5021H6.05784V11.1323Z"
      fill="#686868"
    />
  </svg>
);

export default VisualizationIcon;
