// useCreateUserModal.js
import { useState } from "react";
import axios from "axios";
import constants from "../../constants";
import { createUser } from "../../components/apiRoutes/adminApi/admin-api";
import { useAuth } from "../../context/AuthContext";

const useCreateUserModal = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");
  const [isResponseSuccess, setIsResponseSuccess] = useState(true);
  const { userCreateSuccess, setUserCreateSuccess, authData } = useAuth();

  const handleCreateUserClick = () => {
    setIsModalOpen(true);
    setUserCreateSuccess(!userCreateSuccess);
  };

  const handleModalConfirm = async (username, email, role, fullPhoneNumber) => {
    console.log("from here");
    const idToken =
      authData?.data?.tokenresponse?.IdToken ||
      localStorage.getItem("id_token");
    const accessToken =
      authData?.data?.tokenresponse?.AccessToken ||
      localStorage.getItem("access_token");

    if (!accessToken || !idToken) {
      console.error("Tokens are not available");
      return;
    }

    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        id_token: idToken,
      },
    };
    const requestBody = {
      useremail: email,
      userrole: role,
      name: username,
      ...(fullPhoneNumber && { userphone: fullPhoneNumber }),
    };

    try {
      const response = await axios.post(createUser, requestBody, config);
      // Do not close the modal here
      setResponseMessage(constants.USER_SUCCESS);
      setIsResponseSuccess(true);
      setUserCreateSuccess(true);
      // Optionally, reset form fields here if you're managing them in this hook or context
    } catch (error) {
      if (
        error.response?.data?.message ===
        constants.USER_ACCOUNT_ALREADY_EXISTS ||
        error.response?.data?.message ===
        constants.USER_ACCOUNT_ALREADY_EXISTS_AND_EXPIRED
      ) {
        setResponseMessage(
          constants.MOBILE_NUMBER_ALREADY_EXISTS
        );
    } setResponseMessage(
        error.response?.data.Error ||
          error.response?.data.message ||
          "Failed to create user."
      );
      setIsResponseSuccess(false);
      setUserCreateSuccess(false);
    }
  };

  const handleModalCancel = () => {
    setIsModalOpen(false);
    setUserCreateSuccess(false);
  };

  return {
    isModalOpen,
    handleCreateUserClick,
    handleModalConfirm,
    handleModalCancel,
    responseMessage,
    isResponseSuccess,
    setResponseMessage, // Expose this if you need to manually adjust the response message from the component
  };
};

export default useCreateUserModal;
