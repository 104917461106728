import React, { useState } from "react";
import DropdownSelect from "../../../sharedComponents/DropdownSelect/DropDdownSelect";
import SimulationDuration from "./VirtualWorldSimulationDuration/SimulationDuration";
import VirtualHumanAgeGroup from "./VirtualHumanAgeGroup/VirtualHumanAgeGroup";
import { text } from "../../../constant/text";
import { lang } from "../../../constant/Var";

const SimulationControls = ({
  selected3DWorld,
  selectedParameters,
  dropdown3DWorld,
  handle3DWorldChange,
  selectedAreas,
  selectedSurroundingMaterials,
  selectedTopMaterials,
  selectedFloorMaterials,
  styles,
  selectedPOIs,
  selectedPOI,
  handlePOIChange,
}) => {
  const [pressedIndex, setPressedIndex] = useState(null);

  return (
    <>
      <div className={styles.maincontainer}>
        <div className={styles.leftSection}>
          <h1 className={styles.simulationHeading}>
            {text?.simulations?.simulations_heading[lang]}
          </h1>
          <div className={styles.blocks}>
            <label className={styles.labels}>
              {text?.simulations?.threed_world[lang]}
            </label>
            <DropdownSelect
              style={{ marginTop: 10, marginRight: -5 }}
              options={dropdown3DWorld}
              value={selected3DWorld}
              onChange={handle3DWorldChange}
              label="Select 3D World"
              color={"#fff"}
            />
          </div>
          {/* New Table Section */}
          <div className={styles.blocks}>
            <label className={styles.labels}>
              {text?.simulations?.area_list[lang]}
            </label>
            <table className={styles.customTable}>
              <tbody>
                <tr>
                  <td
                    style={{
                      width: "45%",
                      padding: 3,
                      paddingRight: 2,
                      backgroundColor: "#fff",
                      borderRight: "none",
                      height: "100%",
                    }}
                  >
                    <div
                      style={{
                        backgroundColor: "#F9F9F9",
                        height: "100%",
                        padding: 9,
                        borderRadius: "4px",
                      }}
                    >
                      {selectedAreas.length === 0 ? (
                        <label
                          className={styles.labels}
                          style={{ color: "#A2A2A2" }}
                        >
                          {text?.simulations?.areas[lang]}
                        </label>
                      ) : (
                        <div className={styles.areaList}>
                          {selectedAreas.map((area, index) => (
                            <div
                              key={index}
                              className={`${styles.areaListItem} ${
                                pressedIndex === index ? styles.pressed : ""
                              }`}
                              onClick={() => setPressedIndex(index)}
                            >
                              <div className={styles.areaListItemContent}>
                                <div className={styles.areaIndex}>
                                  {index + 1}.
                                </div>
                                <span className={styles.areaLabel}>
                                  {area.label}
                                </span>
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </td>
                  <td
                    style={{
                      width: "55%",
                      padding: 3,
                      paddingLeft: 2,
                      backgroundColor: "#fff",
                      borderLeft: "none",
                    }}
                  >
                    <div
                      style={{
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ flex: "1 0 5%", paddingBottom: 4 }}>
                        <DropdownSelect
                          style={{ marginRight: -5 }}
                          options={selectedPOIs}
                          value={selectedPOI}
                          onChange={handlePOIChange}
                          label="Select Point of Interest"
                          color={"#F9F9F9"}
                          border={"none"}
                          size={"small"}
                          placeholder={"Point of interests"}
                        />
                      </div>
                      <div style={{ flex: "1 1 94%" }}>
                        <div className={styles.largeContent}>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              paddingBottom: "20px",
                            }}
                          >
                            <label className={styles.materialItemHeading}>
                              {text?.simulations?.materials[lang]}
                            </label>
                          </div>
                          {selectedAreas.length === 0 ? (
                            ""
                          ) : (
                            <div>
                              {/* Surroundings */}
                              <div style={{ paddingBottom: "10px" }}>
                                <label className={styles.materialsubHeading}>
                                  {text?.simulations?.surroundings[lang]}
                                </label>
                                <div className={styles.materialListItem}>
                                  <span className={styles.materialLabel}>
                                    {selectedSurroundingMaterials
                                      .map((area) => area.label)
                                      .join(", ")}
                                  </span>
                                </div>
                              </div>
                              {/* Top */}
                              <div style={{ paddingBottom: "10px" }}>
                                <label className={styles.materialsubHeading}>
                                  {text?.simulations?.top[lang]}
                                </label>
                                <div className={styles.materialListItem}>
                                  <span className={styles.materialLabel}>
                                    {selectedTopMaterials
                                      .map((area) => area.label)
                                      .join(", ")}
                                  </span>
                                </div>
                              </div>
                              {/* Floor */}
                              <div style={{ paddingBottom: "10px" }}>
                                <label className={styles.materialsubHeading}>
                                  {text?.simulations?.floor[lang]}
                                </label>
                                <div className={styles.materialListItem}>
                                  <span className={styles.materialLabel}>
                                    {selectedFloorMaterials
                                      .map((area) => area.label)
                                      .join(", ")}
                                  </span>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className={styles.verticalLine}></div>
        <div className={styles.rightSection}>
          {/* Age group selection */}
          <VirtualHumanAgeGroup styles={styles} />

          {/* Simulation Duration */}
          <SimulationDuration
            selectedParameters={selectedParameters}
            selected3DWorld={selected3DWorld}
          />
        </div>
      </div>
    </>
  );
};

export default SimulationControls;
