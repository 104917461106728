import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import styles from "./ParticipantLogin.module.css";
import OTPInputComponent from "./PartcipantAuth/OTPInputComponent";
import axios from "axios";
import { loginParticipant } from "../../apiRoutes/participantApi/participant-api";
import "react-phone-input-2/lib/style.css";
import { useAuth } from "../../../context/AuthContext";
import "../../../font.css";
import { filteredArray } from "../../../utils/data/countryData";
import phoneStyle from "../PhoneValidation/PhoneNumberValidation.module.css";
import Select from "react-select";
import { ThreeDots } from "react-loader-spinner";
import RDPOTPsInputComponents from "./PartcipantAuth/RDPOTPsInputComponents";
import ParticipantProfileCreation from "./ParticipantsProfileCreationScreen/ParticipantProfileCreation";
import constants from "../../../constants";
import { text } from "../../../constant/text.js";
import { lang } from "../../../constant/Var.js";

const ParticipantLoginScreen = () => {
  const { setSelectedPhoneCodeHashed, setPhoneNumberHashed, setRoleId } =
    useAuth();
  const [isMobileVerified, setIsMobileVerified] = useState(false);
  const [isVerifying, setIsVerifying] = useState(false);
  const [verificationError, setVerificationError] = useState(""); // State to hold verification error messages
  const [selectedPhoneCode, setSelectedPhoneCode] = useState("");
  const [isRDPParticipant, setIsRDPParticipant] = useState(false);
  const [isParticipant, setIsParticipant] = useState(false);
  const [isOtpsVerified, setIsOtpsVerified] = useState(false);
  const [isMobileNumberValid, setIsMobileNumberValid] = useState(false);
  const [isButtondisabled, setIsButtonDisabled] = useState(false)

  const [phoneNumber, setPhoneNumber] = useState("");

  const selectedPhoneCodeObject =
    filteredArray.find((item) => item.phone === selectedPhoneCode) || {};

  const options = filteredArray.map((item) => ({
    value: item.phone,
    label: `+${item.phone}`,
  }));

  const [fullPhoneNumber, setFullPhoneNumber] = useState("");
  useEffect(() => {
    // Remove these items from local storage when the component mounts
    localStorage.removeItem("age");
    localStorage.removeItem("gender");
    localStorage.removeItem("verifying");
    localStorage.removeItem("buttondisabled");
  }, []);

  const handlePhoneCodeChange = (selectedOption) => {
    setIsButtonDisabled(false)
    setVerificationError("")
    if (selectedOption) {
      const newPhoneCode = selectedOption.value;

      if (/^\+?[1-9]\d*-?\d*$/.test(newPhoneCode)) {
        setSelectedPhoneCode(newPhoneCode);
        setPhoneNumber("");

        setFullPhoneNumber(`+${newPhoneCode}${phoneNumber}`);
        setSelectedPhoneCodeHashed(newPhoneCode);
        localStorage.setItem("cc", newPhoneCode.length);
      } else {
      }
    } else {
      setSelectedPhoneCode("");
      setPhoneNumber("");
      setFullPhoneNumber("");
    }
  };

  const handlePhoneNumberChange = (event) => {
    setIsButtonDisabled(false)
    if (!Boolean(selectedPhoneCode)) return;
    const inputNumber = event.target.value;

    // Reset the error message if the input is empty
    if (inputNumber === "") {
      setVerificationError("");
      setPhoneNumber("");
      formik.setFieldValue("phoneNumber", `+${selectedPhoneCode}`);
      setFullPhoneNumber(`+${selectedPhoneCode}`);
      localStorage.setItem("pn", 0);
      setVerificationError(""); // Clear the verification error
      return;
    }

    if (/^\d*$/.test(inputNumber)) {
      setVerificationError("")
      setPhoneNumber(inputNumber);
      setPhoneNumberHashed(inputNumber);
      formik.setFieldValue(
        "phoneNumber",
        `+${selectedPhoneCode}${inputNumber}`
      );
      setFullPhoneNumber(`+${selectedPhoneCode}${inputNumber}`);
      localStorage.setItem("pn", inputNumber.length);

      if (!inputNumber || !inputNumber.length) {
        setIsMobileNumberValid(false);
        setVerificationError(text?.validations.phone_number_required[lang]);
      } else if (inputNumber.length !== selectedPhoneCodeObject?.phoneLength) {
        setIsMobileNumberValid(false);
        setVerificationError(text?.validations.invalid_mobile_number[lang]);
      } else {
        setIsMobileNumberValid(true);
        setVerificationError("");
      }
    }
  };


  const handleInputChange = (inputValue, actionMeta) => {
    if (actionMeta.action === "input-change") {
      const isValidInput = /^[\+\d-]{0,7}$/.test(inputValue);
      if (!isValidInput) {
        return "";
      } 
    }
    if (actionMeta.action === "set-value") {
      setIsMobileNumberValid(false);
    }
    return inputValue;
  };

  const formik = useFormik({
    initialValues: {
      phoneNumber: "",
    },
    validationSchema: Yup.object({
      phoneNumber: Yup.string()
        .required(text?.validations.phone_number_required[lang])
        .matches(/^\+?\d{1,3}-?\d{1,15}$/, text?.validations.invalid_number_format[lang]),
    }),
    onSubmit: () => {},
  });

  const handleGetOTP = () => {
    setVerificationError("");
    if (
      !phoneNumber ||
      !selectedPhoneCodeObject?.phoneLength || // Check if phoneLength is defined
      phoneNumber.length !== selectedPhoneCodeObject.phoneLength
    ) {
      setVerificationError(text?.sign_in[lang]);
      formik.setFieldError("phoneNumber", text?.validations.invalid_mobile_number[lang]);
      setIsVerifying(false);
      return;
    }
    setIsVerifying(true); // Start the loader
    axios
      .post(loginParticipant, { phone_number: fullPhoneNumber })
      .then((response) => {
        if (response?.data.success) {
          setIsVerifying(false);
          if (response?.data?.data?.role === constants.RDP) {
            setIsRDPParticipant(true);
          } else if (response?.data?.data?.role === constants.PARTICIPANT) {
            setIsParticipant(true);
          }
          setIsMobileVerified(true);
          setRoleId(response?.data?.data?.role);
        } else {
          setVerificationError(text?.validations.participant_doesnt_exist[lang]);
          setIsMobileVerified(false);
        }
        localStorage.setItem(
          "otp_session",
          response.data.data.login_response.data.Session
        );
      })
      .catch((err) => {
        var loginErrorMessage = "";
        setIsMobileVerified(false);
        console.log(err.response);
        if (err.message === "Network Error") {
          loginErrorMessage = text?.validations.something_is_wrong[lang];
        } else if (
          err.response?.data?.message.includes(
            "Access is denied for this User !"
          )
        ) {
          loginErrorMessage = text?.validations.number_is_disabled[lang];
          setIsButtonDisabled(true)
        } else {
          loginErrorMessage =
          text?.validations.number_not_found[lang];
          setIsButtonDisabled(true)
        }
        setVerificationError(loginErrorMessage);
        setIsVerifying(false);
        console.log(err);
      });
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter" && !isVerifying && isMobileNumberValid) {
      event.preventDefault(); // Prevent the default action to avoid form submission if using a form tag
      handleGetOTP();
    }
  };

  const handleKeyDown = (event) => {
    // Allow only numeric keys and certain control keys
    const allowedKeys = [
      "Backspace",
      "ArrowLeft",
      "ArrowRight",
      "ArrowUp",
      "ArrowDown",
      "Delete",
      "Tab",
      "Enter",
      "+",
      "-",
      "0",
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
    ];

    // Check if the pressed key is not in the allowed keys list
    if (!allowedKeys.includes(event.key)) {
      event.preventDefault(); // Prevent the key press
    }
  };

  return (
    <div
      className={
        isRDPParticipant ? styles.signInSectionForRdp : styles.signInSection
      }
    >
      <div
        className={`${
          isOtpsVerified ? styles.signInBoxSuccess : styles.signInBox
        }`}
      >
        {!isOtpsVerified ? (
          <>
            <h1 className={styles.signinTitle}>{text?.virtual_humans[lang]}</h1>
            <form onSubmit={formik.handleSubmit}>
              <div className={styles.inputGroup}>
                <h3 className={styles.signInHeading}>{text?.sign_in[lang]}</h3>
                {!isRDPParticipant && (
                  <div className={styles.labelForMobileAndEdit}>
                    <label
                      htmlFor="phoneNumber"
                      className={styles.mobileNumberLabel}
                    >
                      {text?.mobile_number[lang]}
                    </label>
                  </div>
                )}

                {!isRDPParticipant && (
                  <div className={phoneStyle.container}>
                    <Select
                      className={phoneStyle.dropdown}
                      value={
                        options.find(
                          (option) => option.value === selectedPhoneCode
                        ) || null
                      }
                      onChange={handlePhoneCodeChange}
                      onInputChange={handleInputChange}
                      options={options}
                      isDisabled={isMobileVerified || isVerifying}
                      components={{
                        IndicatorSeparator: () => null,
                      }}
                      isSearchable={true}
                      placeholder={
                        <div className={styles.selectPlaceholderText}>
                          {text?.select[lang]}
                        </div>
                      }
                      styles={{
                        control: (styles, state) => ({
                          ...styles,
                          backgroundColor:
                            isMobileVerified || isVerifying
                              ? "#f9f9f9"
                              : "white",
                          border: verificationError
                            ? "1px solid rgba(227, 74, 71, 1)"
                            : isMobileVerified || isVerifying
                            ? ""
                            : "1px solid #5e5e5e",
                          boxShadow: state.isFocused ? "0 0 0 1px #5E5E5E" : "",
                          borderColor: state.isFocused
                            ? "#5E5E5E"
                            : styles.borderColor,
                          "&:hover": {
                            borderColor: state.isFocused
                              ? "#5E5E5E"
                              : styles["&:hover"].borderColor,
                          },
                          "@media only screen and (max-width: 769px)": {
                            height: "40px",
                          },
                          "@media only screen and (min-width: 769px)": {
                            height: "48px",
                          },
                        }),
                        dropdownIndicator: (base) => ({
                          ...base,
                          color: "#5E5E5E", // Change arrow color here
                        }),
                        indicatorSeparator: (base) => ({
                          display: "none", // Hides the indicator separator
                        }),
                      }}
                      onKeyDown={handleKeyDown}
                    />

                    <input
                      className={`${phoneStyle.phoneNumberInput} ${
                        isMobileVerified ? phoneStyle.disabledInputStyle : ""
                      } ${
                        (verificationError && !isMobileVerified)
                          ? phoneStyle.inputError
                          : ""
                      }`}
                      type="text"
                      value={phoneNumber}
                      onChange={handlePhoneNumberChange}
                      maxLength={selectedPhoneCodeObject.phoneLength}
                      disabled={isMobileVerified || isVerifying}
                      placeholder={text?.enter_mobile_number[lang]}
                      onKeyPress={handleKeyPress}
                    />
                  </div>
                )}

                {formik.errors.phoneNumber && formik.touched.phoneNumber && (
                  <div className={styles.error}>
                    {formik.errors.phoneNumber}
                  </div>
                )}
                {verificationError && (
                  <div className={styles.error}>{verificationError}</div>
                )}
              </div>
              <div className={styles.buttonStyle}>
                {!isMobileVerified && (
                  <button
                    type="button"
                    className={`${styles.otpButton} ${
                      isMobileNumberValid && !isButtondisabled
                        ? styles.otpButtonActive
                        : ""
                    }`}
                    onClick={handleGetOTP}
                    disabled={
                      isVerifying ||
                      !isMobileNumberValid ||
                      isButtondisabled
                    } // Disable the button while loading
                  >
                    {isVerifying ? (
                      <ThreeDots
                        height="20" // Adjust size as needed
                        width="30" // Adjust size as needed
                        color="#fff" // Set to gray as per your requirement
                        ariaLabel="loading"
                      />
                    ) : (
                      text?.get_otp[lang]
                    )}
                  </button>
                )}
              </div>
            </form>
            {isParticipant && (
              <OTPInputComponent
                otpsVerified={() => setIsOtpsVerified(true)}
                mobileNumber={fullPhoneNumber}
              />
            )}
            {isRDPParticipant ? (
              <RDPOTPsInputComponents
                mobileNumber={fullPhoneNumber}
                selectedPhoneCode={selectedPhoneCode}
                phoneNumber={phoneNumber}
                showEmailOTP={true}
                showMobileOTP={true}
                phoneOTPLabel={text?.mobile_number_otp[lang]}
                emailOTPLabel={text?.email_otp[lang]}
                otpsVerified={() => setIsOtpsVerified(true)}
              />
            ) : (
              <></>
            )}
          </>
        ) : (
          <></>
        )}
        {isOtpsVerified ? <ParticipantProfileCreation /> : <></>}
      </div>
    </div>
  );
};

export default ParticipantLoginScreen;
