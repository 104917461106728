import React, { useEffect, useState } from "react";
import styles from "./RDPNav.module.css";
import userProfileIcon from "../../../assets/admin-icon/userProfileIcon.png";
import neomLogo2 from "../../../assets/images/neomUpdatedLogo.png";
import hamburgerIcon from "../../../assets/admin-icon/hamburgerIcon.png";
import { useNavigate } from "react-router-dom";
import { ADMIN_HOMEPAGE } from "../../apiRoutes/adminRoutes/adminRoutes";
import AdminLogoutModal from "../../../utils/Modals/AdminModal/AdminLogoutModal/AdminLogoutModal";
import { useAuth } from "../../../context/AuthContext";
import RDPNavigation from "../RDPNavigation/RDPNavigation";
import { useRDP } from "../../../context/rdpContext";
import { Popover } from "@mui/material";
import LogoutIcon from "../../../assets/svgicons/LogoutIcon";
import CrossIcon from "../../../assets/svgicons/CrossIcon";
import { text } from "../../../constant/text.js";
import { lang } from "../../../constant/Var.js";
import axios from "axios";
import { logoutProfile } from "../../apiRoutes/participantApi/participant-api.jsx";
import Banner from "../../../sharedComponents/Banner/Banner.js";

const PDPNav = () => {
  const navigate = useNavigate();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isLogoutModalOpen, setIsLogoutModalOpen] = useState(false);
  const { signOut, decodedData, authData } = useAuth();
  const { setSelectedFilters } = useRDP();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [logoutInitiated, setLogoutInitiated] = useState(false);

  const [isMobile, setIsMobile] = useState(window.innerWidth < 769); // State to track screen size

  // Event listener to update state based on screen size
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [anchorEl, setAnchorEl] = useState(null);
  const accessToken = authData?.data?.tokenresponse?.AccessToken;
  const [networkError, setNetworkError] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const handleResize = () => {
    setWindowWidth(window.innerWidth);

    // Update isMobile based on the new window size
    setIsMobile(window.innerWidth < 769);

    if (window.innerWidth > 769 && isMenuOpen) {
      setIsMenuOpen(false); // Ensure menu is closed when transitioning to a larger screen
    }
  };

  // Effect to listen to window resize events
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, [isMenuOpen]); // Include isMenuOpen in the dependency array

  const handleLogoutInitiated = (isOpen) => {
    setLogoutInitiated(isOpen);
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleLogoutClick = () => {
    setIsLogoutModalOpen(true);
    setIsDropdownOpen(false);
    handleClose();
  };

  const handleModalCancel = () => {
    setIsLogoutModalOpen(false);
  };

  const handleModalConfirm = () => {
    console.log("Confirmed logout.");
    //Below API is responsible for user logout out of all devices if a user logs out from one device
    const config = {
      method: "post",
      url: logoutProfile,
      headers: {
        access_token: accessToken,
      },
    };

    axios
      .request(config)
      .then((response) => {
        navigate("/user");
        setSelectedFilters({
          gender: [],
          statuses: [],
          fromAge: null,
          toAge: null,
          inputValue: "",
        });
        signOut();
      })
      .catch((error) => {
        if (error.message.includes("Network Error")) {
          setNetworkError(true)
        } else{
          console.error("Error Loggingout the profile:", error);
          signOut();
        }   
      })
      .finally(() => {});
  };

  const handleRefresh = () => {
    window.location.reload();
  };

  return (
    <>
      <header className={styles.navHeader}>
        {networkError && <Banner message={text?.validations?.something_went_wrong_refresh[lang]} onRefresh={handleRefresh} action={text?.refresh[lang]}/>}
        <div className={styles.leftSection}>
          <div className={styles.logo}>
            <img
              src={neomLogo2}
              alt="Company Logo"
              onClick={() => navigate(ADMIN_HOMEPAGE)}
              className={styles.logoRedirect}
              draggable="false"
            />
          </div>
          <div className={styles.welcomeMessage}>
            {text?.welcome[lang]},{" "}
            <span style={{ fontWeight: 700 }}> {decodedData?.name}</span>
          </div>
        </div>

        <div className={styles.rightSection}>
          {isMobile ? (
            // Toggle between hamburger icon and cross icon based on isMenuOpen
            isMenuOpen ? (
              <div
                onClick={toggleMenu}
                className={`${styles.closeDrawerIcon} ${
                  logoutInitiated ? styles.underModal : ""
                }`}
              >
                <CrossIcon />
              </div>
            ) : (
              <div className={styles.hamburgerIcon} onClick={toggleMenu}>
                <img
                  src={hamburgerIcon}
                  alt="Menu"
                  className={styles.hamburgerIcon}
                  onClick={toggleDropdown}
                />
              </div>
            )
          ) : (
            // Default view for larger screens
            <>
              <span className={styles.adminText}>
                {text?.roles[decodedData?.["custom:role"]]?.[lang]}
              </span>

              <div className={styles.profileIcon} onClick={handleClick}>
                <img
                  src={userProfileIcon}
                  alt="Profile Icon"
                  draggable="false"
                />
              </div>

              <Popover
                id="logout-popover"
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                // Apply styles directly using the sx prop
                slotProps={{
                  paper: {
                    sx: {
                      borderRadius: "4px",
                      marginLeft: "-10px !important",
                      marginTop: "-2px",
                      boxShadow: "0 4px 8px 2px rgba(0, 0, 0, 0.1)",
                    },
                  },
                }}
              >
                <div className={styles.dropdownMenu}>
                  <div
                    className={styles.imgTextWrapper}
                    onClick={handleLogoutClick}
                  >
                    <LogoutIcon styles={styles} />
                    <span>{text?.logout[lang]}</span>
                  </div>
                </div>
              </Popover>
            </>
          )}
        </div>
        <div
          className={`${styles.drawer} ${isMenuOpen ? styles.drawerOpen : ""}`}
        >
          <RDPNavigation
            activeIcon={"activeIcon"}
            setActiveIcon={"setActiveIcon"}
            handleLogoutClick={handleLogoutClick}
            onLogoutInitiated={handleLogoutInitiated}
            handleModalConfirm={handleModalConfirm}
            toggleMenu={toggleMenu}
          />
        </div>
      </header>
      <AdminLogoutModal
        isOpen={isLogoutModalOpen}
        onCancel={handleModalCancel}
        onConfirm={handleModalConfirm}
      />
    </>
  );
};

export default PDPNav;
