import React from "react";

const GreenTick = ({ styles }) => (
  <svg width="144" height="144" viewBox="0 0 144 144" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_3770_6305)">
<path d="M115.076 16.8077C117.066 14.8156 120.295 14.8149 122.286 16.8062L140.398 34.9181C142.388 36.9079 142.389 40.1336 140.4 42.1246L54.2465 128.389C52.2554 130.383 49.0245 130.383 47.0333 128.389L3.6001 84.9046C1.61148 82.9136 1.61243 79.6878 3.60223 77.698L21.7156 59.5846C23.7063 57.5939 26.9337 57.5939 28.9244 59.5846L47.9355 78.5957C49.429 80.0892 51.8505 80.0886 53.3433 78.5945L115.076 16.8077ZM121.386 32.725C119.893 31.2221 117.463 31.2189 115.967 32.7178L53.3272 95.4683C51.8409 96.9572 49.4309 96.9654 47.9344 95.4867L27.9748 75.7636C26.4984 74.3046 24.1276 74.2903 22.6337 75.7313L19.6642 78.5954C18.1242 80.0809 18.1031 82.541 19.6174 84.0527L47.9367 112.322C49.4301 113.812 51.8488 113.811 53.3409 112.319L124.446 41.2139C125.936 39.7247 125.94 37.3115 124.456 35.817L121.386 32.725Z" fill="#016F4A"/>
</g>
<defs>
<clipPath id="clip0_3770_6305">
<rect width="144" height="144" fill="white"/>
</clipPath>
</defs>
</svg>

);

export default GreenTick;
