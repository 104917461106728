import { useEffect, useRef, useCallback } from "react";
import { useLocation } from "react-router-dom";

export const useInactivityDetector = (timeout, onInactive) => {
  const timerRef = useRef(null);
  const lastActivityRef = useRef(new Date().getTime()); // Track last activity time
  const location = useLocation();

  const resetTimer = useCallback(() => {
    clearTimeout(timerRef.current);
    lastActivityRef.current = new Date().getTime(); // Update last activity time
    timerRef.current = setTimeout(() => {
      console.log("User is inactive");
      onInactive();
    }, timeout);
  }, [timeout, onInactive]);

  useEffect(() => {
    const handleActivity = () => {
      resetTimer();
    };

    // Event listeners for desktop and mobile activity
    document.addEventListener("mousemove", handleActivity);
    document.addEventListener("keydown", handleActivity);
    document.addEventListener("scroll", handleActivity);
    document.addEventListener("touchstart", handleActivity);
    document.addEventListener("touchmove", handleActivity);

    resetTimer();

    return () => {
      clearTimeout(timerRef.current);
      document.removeEventListener("mousemove", handleActivity);
      document.removeEventListener("keydown", handleActivity);
      document.removeEventListener("scroll", handleActivity);
      document.removeEventListener("touchstart", handleActivity);
      document.removeEventListener("touchmove", handleActivity);
    };
  }, [resetTimer]);

  useEffect(() => {
    // Check for inactivity periodically, even if the app is in the background
    const checkInactivity = () => {
      const currentTime = new Date().getTime();
      const timePassed = (currentTime - lastActivityRef.current) / 1000;
      if (timePassed > timeout / 1000) {
        console.log("User is inactive due to background");
        onInactive();
      }
    };

    const interval = setInterval(checkInactivity, 10000); // Check every 10 seconds

    return () => {
      clearInterval(interval);
    };
  }, [timeout, onInactive]);

  useEffect(() => {
    // Reset timer on route change
    if (location.pathname === "/participant-profile-view") {
      resetTimer();
    }
  }, [location.pathname, resetTimer]);

  return null;
};
