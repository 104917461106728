import { createContext, useContext, useState } from "react";

const RDPContext = createContext();

export const RDPProvider = ({ children }) => {
  const [selectedFilters, setSelectedFilters] = useState({
    gender: [],
    statuses: [],
    fromAge: null,
    toAge: null,
    inputValue: "",
  });

  const RDPStatus = [
    "Invitation Sent",
    "Mobile Number Verified",
    "Profile Completed",
    "Diagnostics Completed",
    "Experience Completed",
  ];
  const [updateTrigger, setUpdateTrigger] = useState(0); // State to trigger updates

  // Function to trigger updates
  const triggerTableUpdate = () => {
    setUpdateTrigger((prev) => prev + 1);
  };

  return (
    <RDPContext.Provider
      value={{
        selectedFilters,
        setSelectedFilters,
        RDPStatus,
        triggerTableUpdate,
      }}
    >
      {children}
    </RDPContext.Provider>
  );
};

export const useRDP = () => useContext(RDPContext);
