import React from 'react';
import styles from './AdminLogoutModal.module.css';
import { text } from '../../../../constant/text';
import { lang } from "../../../../constant/Var.js";
const AdminLogoutModal = ({ isOpen, onCancel, onConfirm }) => {
  if (!isOpen) return null;

  return (
    <div className={styles.modalOverlay}>
      <div className={styles.modalContent}>
        <h2 className={styles.modalTitle}>{text?.logout[lang]}</h2>
        <div className={styles.modalInfoContainer}>
        <span className={styles.modalInfo}>{text?.logout_confirm[lang]}</span>
        </div>
        <div className={styles.modalActions}>
          <button onClick={onCancel} className={styles.modalCancel}>{text?.cancel[lang]}</button>
          <button onClick={onConfirm} className={styles.modalConfirm}>{text?.confirm[lang]}</button>
        </div>
      </div>
    </div>
  );
};

export default AdminLogoutModal