import React, { useEffect, useRef, useState } from "react";
import styles from "./CreateUserModal.module.css";
import PrimaryPhoneNumber from "../../../../sharedComponents/PrimaryPhoneNumber";
import constants from "../../../../constants";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import { ThreeDots } from "react-loader-spinner";
import { capitalizeFirstLetter } from "../../../UtilityFunction/capitalizeFirstLetter";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import ExpandLessRoundedIcon from "@mui/icons-material/ExpandLessRounded";
import { text } from "../../../../constant/text";
import { lang } from "../../../../constant/Var";

const CreateUserModal = ({
  isOpen,
  onCancel,
  onConfirm,
  responseMessage,
  isResponseSuccess,
  setResponseMessage,
}) => {
  if (
    responseMessage == constants.USER_ACCOUNT_ALREADY_EXISTS_AND_EXPIRED ||
    responseMessage == constants.USER_ACCOUNT_ALREADY_EXISTS
  ) {
    responseMessage = constants.MOBILE_NUMBER_ALREADY_EXISTS;
  }
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [roleError, setRoleError] = useState(""); // Add state to keep track of role selection error
  const dropdownRef = useRef(null);
  const [emailError, setEmailError] = useState("");
  const [username, setUsername] = useState("");
  const [usernameError, setUsernameError] = useState("");
  const [displayRole, setDisplayRole] = useState("");
  const [fullPhoneNumber, setFullPhoneNumber] = useState("");
  const [fullPhoneNumberError, setFullPhoneNumberError] = useState(""); // Add state to keep track of role selection error
  const [isVerifying, setIsVerifying] = useState(false);
  const [selectedPhoneCode, setSelectedPhoneCode] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [verificationError, setVerificationError] = useState(""); // State to hold verification error messages
  const [userInputChanged, setUserInputChanged] = useState(false);
  const isUserAccountAlreadyExists =
    responseMessage === constants.USER_ACCOUNT_ALREADY_EXISTS ||
    responseMessage === constants.EMAIL_ALREADY_EXISTS ||
    responseMessage === constants.USER_ACCOUNT_ALREADY_EXISTS_AND_EXPIRED;
  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  useEffect(() => {
    if (responseMessage === constants.USER_SUCCESS) {
      setFullPhoneNumber(`+${selectedPhoneCode} ${phoneNumber}`);
    }
  }, [responseMessage, selectedPhoneCode, phoneNumber]);

  useEffect(() => {
    setUserInputChanged(true);
  }, [username, email, role, selectedPhoneCode, phoneNumber]);

  const isValidEmail = (email) => {
    // Improved email validation regex
    return /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email);
  };

  const roles = ["Raw Data Provider", "Urban Planner", "Admin"];
  const roleAbbreviations = {
    "Raw Data Provider": "RDP",
    "Urban Planner": "UP",
    "Admin": "admin",
  };

  if (!isOpen) return null;

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    let isValidForm = true;
    setIsVerifying(false);

    if (responseMessage === constants.USER_SUCCESS) {
      setEmail("");
      setRole("");
      setIsDropdownOpen(false);
      setRoleError("");
      setEmailError("");
      setUsername("");
      setUsernameError("");
      setDisplayRole("");
      setFullPhoneNumber("");
      setFullPhoneNumberError("");
      setResponseMessage("");
      setSelectedPhoneCode("");
      setPhoneNumber("");
      setVerificationError("");
      setUserInputChanged(false);
      return;
    }

    // Validate username
    if (!username || username.trim().length <= 1) {
      // Assuming you have a setUsernameError state similar to setEmailError
      setUsernameError(text?.user_creation_errors?.firstname_character_length[lang]);
      isValidForm = false;
    } else {
      setUsernameError("");
    }

    // Validate email
    if (!email || !isValidEmail(email)) {
      setEmailError(text?.validations?.invalid_email_address[lang]);
      isValidForm = false;
    } else {
      setEmailError("");
    }

    // Validate role selection
    if (!role) {
      setRoleError(text?.user_creation_errors?.please_select_a_role[lang]);
      isValidForm = false;
    } else {
      setRoleError("");
    }
    let abbreviatedRole = role;
    if (!role) {
      setRoleError(text?.user_creation_errors?.please_select_a_role[lang]);
      isValidForm = false;
    } else {
      setRoleError("");
      abbreviatedRole = roleAbbreviations[role] || role;
    }

    if (displayRole === "RDP") {
      if (!fullPhoneNumber) {
        setFullPhoneNumberError(
          text?.user_creation_errors.please_enter_valid_number[lang]
        );
        isValidForm = false;
      } else {
        setFullPhoneNumberError("");
        isValidForm = true;
      }

      if (verificationError) isValidForm = false;
      else {
        setVerificationError("");
        isValidForm = true;
      }
    } else {
      setFullPhoneNumberError("");
      setVerificationError("");
    }

    if (isValidForm) {
      try {
        setIsVerifying(true); // Start the loader
        await onConfirm(username, email, abbreviatedRole, fullPhoneNumber);

        setIsVerifying(false);
      } catch (error) {
        console.error("API call failed:", error);
        setIsVerifying(false);
      } finally {
        setUserInputChanged(false);
      }
    }
    setUserInputChanged(false);
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
    if (isDropdownOpen) {
      setRoleError(""); // Clear error message when dropdown is toggled
      setResponseMessage("");
    }
  };

  const selectRole = (selectedRole) => {
    setRole(selectedRole); // Set the actual role value
    setDisplayRole(roleAbbreviations[selectedRole]); // Set the display version of the role
    setIsDropdownOpen(false);
    setRoleError("");
  };

  const handleCancel = () => {
    setEmail("");
    setRole("");
    setIsDropdownOpen(false);
    setRoleError("");
    setEmailError("");
    setUsername("");
    setUsernameError("");
    setFullPhoneNumber("");
    setFullPhoneNumberError("");
    setResponseMessage("");
    setSelectedPhoneCode("");
    setPhoneNumber("");
    setVerificationError("");
    setDisplayRole("");
    onCancel();
  };

  const handleUserName = (value) => {
    const regex = /^[a-zA-Z]+$/;
    if (value && !regex.test(value)) return;
    if (value && value.length > 15) return;
    setUsername(value);
    if (value.trim().length <= 1) {
      // If one character or less, set the error message
      setUsernameError(
        text?.user_creation_errors.firstname_character_length[lang]
      );
      if (!value || value.trim().length === 0) {
        setUsernameError(
          text?.user_creation_errors.firstname_is_required[lang]
        );
      }
    } else {
      // If more than one character, clear the error message
      setUsernameError("");
    }
    setUsername(capitalizeFirstLetter(value));
  };

  const handleEmailChange = (e) => {
    const value = e.target.value;
    setEmail(value);
    if (!value || !isValidEmail(value)) {
      setEmailError(text?.validations?.invalid_email_address[lang]);
    } else {
      setEmailError("");
    }
    setResponseMessage("");
  };
  return (
    <div className={styles.modalOverlay}>
      <div className={styles.modalContent}>
        <h2 className={styles.modalTitle}>{text?.create_user[lang]}</h2>
        <form onSubmit={handleFormSubmit}>
          <div className={styles.formGroup}>
            <label htmlFor="username" className={styles.label}>
              {text?.first_name[lang]}
            </label>
            <input
              id="username"
              type="text"
              value={username}
              onChange={(e) => handleUserName(e.target.value)}
              className={`${styles.modalInput} 
                ${usernameError ? styles.inputError : ""} 
                ${
                  responseMessage === constants.USER_SUCCESS
                    ? styles.disabledField
                    : ""
                }`}
              required
              placeholder={text?.enter_only_first_name[lang]}
              disabled={
                responseMessage === constants.USER_SUCCESS || isVerifying
              }
            />
            {usernameError && (
              <div className={styles.usernameError}>{usernameError}</div>
            )}
          </div>
          <div className={styles.formGroup}>
            <label htmlFor="email" className={styles.label}>
              {text?.email_id[lang]}
            </label>
            <input
              id="email"
              type="email"
              value={email}
              onChange={(e) => handleEmailChange(e)}
              className={`
                ${styles.modalInput} 
                ${
                  emailError ||
                  responseMessage === constants.EMAIL_ALREADY_EXISTS
                    ? styles.inputError
                    : ""
                } 
                ${
                  responseMessage === constants.USER_SUCCESS
                    ? styles.disabledField
                    : ""
                }
              `}
              placeholder="Example@email.com"
              disabled={
                responseMessage === constants.USER_SUCCESS || isVerifying
              }
            />
            {emailError && (
              <div className={styles.emailError}>{emailError}</div>
            )}
          </div>
          <div className={styles.formGroup} ref={dropdownRef}>
            <label htmlFor="role" className={styles.label}>
              {responseMessage === constants.USER_SUCCESS
                ? text?.role[lang]
                : text?.assign_role[lang]}
            </label>
            {responseMessage === constants.USER_SUCCESS || isVerifying ? (
              <input
                id="role"
                type="text"
                value={role}
                className={`${styles.modalInput} ${
                  responseMessage === constants.USER_SUCCESS
                    ? styles.disabledField
                    : ""
                }`}
                disabled={true}
              />
            ) : (
              <>
                <div
                  className={styles.customSelect}
                  style={{ opacity: "1" }}
                  onClick={toggleDropdown}
                >
                  <div className={styles.dropDownAlign}>
                    {text?.roles[role]?.[lang]|| (
                      <span className={styles.placeHolderField}>
                        {text?.select_role[lang]}...
                      </span>
                    )}
                    <span className={styles.dropdownArrow}>
                      {isDropdownOpen ? (
                        <ExpandLessRoundedIcon style={{ color: "#5E5E5E" }} />
                      ) : (
                        <ExpandMoreRoundedIcon style={{ color: "#5E5E5E" }} />
                      )}
                    </span>
                  </div>
                  {isDropdownOpen && (
                    <div className={styles.dropdown}>
                      {roles.map((r, index) => (
                        <div
                          key={index}
                          className={styles.dropdownItem}
                          onClick={() => selectRole(r)}
                        >
                          {text?.roles[r]?.[lang]}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </>
            )}
            {roleError && <div className={styles.roleError}>{roleError}</div>}
          </div>
          {displayRole === "RDP" && (
            <div className={styles.formGroup}>
              <label htmlFor="mobilenumber" className={styles.label}>
                {text?.mobile_number[lang]}
              </label>

              {/* Allow editing of mobile number when creating a new user */}
              <PrimaryPhoneNumber
                id={"mobilenumber"}
                setFullPhoneNumber={setFullPhoneNumber}
                isDisabled={
                  responseMessage === constants.USER_SUCCESS || isVerifying
                }
                selectedPhoneCode={selectedPhoneCode}
                setSelectedPhoneCode={setSelectedPhoneCode}
                phoneNumber={phoneNumber}
                setPhoneNumber={setPhoneNumber}
                verificationError={verificationError}
                setVerificationError={setVerificationError}
                setResponseMessage={setResponseMessage}
                responseMessage={responseMessage}
              />

              {fullPhoneNumberError && (
                <div className={styles.roleError}>{fullPhoneNumberError}</div>
              )}
            </div>
          )}

          <div>
            {responseMessage && !userInputChanged ? (
              <div className={styles.responseTextBox}>
                <div className={styles.iconTextWrapper}>
                  {isResponseSuccess ? (
                    <CheckCircleIcon sx={{ color: "#016f4a" }} />
                  ) : (
                    <CancelIcon sx={{ color: "#e34a47" }} />
                  )}
                  <span
                    className={
                      isResponseSuccess ? styles.successText : styles.errText
                    }
                  >
                    {responseMessage}
                  </span>
                </div>
              </div>
            ) : null}
            <div className={styles.modalActions}>
              <button
                type="button"
                onClick={handleCancel}
                className={styles.modalCancel}
                disabled={isVerifying}
              >
                {text?.close[lang]}
              </button>
              <button
                type="submit"
                className={styles.modalConfirm}
                disabled={
                  isVerifying || // Verification in progress
                  !username || // Username is empty
                  username.trim().length <= 1 || // Username is one character or less
                  !email || // Email is empty
                  !isValidEmail(email) || // Email is not valid
                  !role || // Role is not selected
                  (displayRole === "RDP" && !fullPhoneNumber) ||
                  (displayRole === "RDP" && verificationError) || // There's a verification error for the phone number
                  (displayRole === "RDP" && !selectedPhoneCode) ||
                  (displayRole === "RDP" && !phoneNumber) ||
                  isUserAccountAlreadyExists
                }
              >
                {isVerifying ? (
                  <ThreeDots
                    height="20"
                    width="30"
                    color="#fff"
                    ariaLabel="loading"
                  />
                ) : responseMessage === constants.USER_SUCCESS ? (
                  text?.create_another[lang]
                ) : (
                  text?.create[lang]
                )}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CreateUserModal;
