import React from 'react';
import styles from './CustomInput.module.css'
import CalendarIcon from '../../assets/svgicons/CalendarIcon';

const CustomInput = React.forwardRef(({ value, onClick, placeholder, isSubmitted, isLarge }, ref) => (
  <div className={isLarge? styles.largeInputContainer : styles.customInputContainer}>
    <input
      type="text"
      value={value}
      onClick={onClick}
      ref={ref}
      className={`${isLarge? styles.largeinputField : styles.inputField} ${isSubmitted ? styles.submitted : ''}`}
      placeholder={placeholder}
      readOnly
      disabled={isSubmitted}
    />
    <span className={isLarge? styles.largeicon : styles.icon}>
      <CalendarIcon />
    </span>
  </div>
));

export default CustomInput;
