import React, { useContext } from "react";
import { SimulationContext } from "../../../../context/SimulationCotext";
import CustomValueContainer from "../../../../sharedComponents/CustomValueContainer/CustomValueContainer";
import { text } from "../../../../constant/text";
import { lang } from "../../../../constant/Var";

const ageGroupOptions = [
  { value: "18-28", label: "18-28" },
  { value: "29-45", label: "29-45" },
  { value: "46-60", label: "46-60" },
];

const VirtualHumanAgeGroup = ({ styles }) => {

  const { simulationState, updateSimulationState } =
    useContext(SimulationContext);

  const handleChange = (gender, selectedOption) => {
    // Create a new object for age group selections including the updated one
    const newAgeGroupSelections = {
      ...simulationState.ageGroupSelections,
      [gender]: selectedOption,
    };

    // Update the shared state in context
    updateSimulationState({
      ...simulationState,
      ageGroupSelections: newAgeGroupSelections,
    });
  };
  return (
    <div className={styles.blocks} style={{marginBlock: "5px"}}>
      <h1 className={styles.simulationHeading}>
        {text?.virtual_humans[lang]} - {text?.simulations?.age_groups[lang]}
      </h1>
      <div className={styles.gendercontainer}>
        {["male", "female", "nonBinary"].map((gender) => (
          <div key={gender} className={styles.genderdropdownBlock}>
            <label className={styles.labels}>
              {gender === "nonBinary"
                ? text?.genders[gender]?.[lang]
                : gender.charAt(0).toUpperCase() + gender.slice(1)}
            </label>
            <CustomValueContainer
              options={ageGroupOptions}
              onChange={(option) => handleChange(gender, option)}
              menuHeaderText={text?.simulations?.select_age_group[lang]}
              defaultValue={[]}
              value={simulationState.ageGroupSelections[gender]}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default VirtualHumanAgeGroup;
