import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import styles from "./ParticipantProfileUpdatescreen.module.css";
import {
  participantProfileView,
  updateProfile,
} from "../../../apiRoutes/participantApi/participant-api";
import { useAuth } from "../../../../context/AuthContext";
import { toast } from "react-toastify";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { ThreeDots } from "react-loader-spinner";
import { FormControl, MenuItem, Select } from "@mui/material";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import ThreeDotsLoader from "../../../../sharedComponents/ThreeDotsLoader";
import constants from "../../../../constants";
import { text } from "../../../../constant/text";
import { lang } from "../../../../constant/Var";

const isMobile = window.matchMedia("(max-width: 767px)").matches;

const validationSchema = Yup.object({
  firstName: Yup.string(),
  age: Yup.number()
    .positive(text?.validations?.age_must_be_positive[lang])
    .integer(text?.validations?.age_must_be_integer[lang])
    .min(18, text?.validations?.age_limit_is_18_60[lang])
    .max(60, text?.validations?.age_limit_is_18_60[lang])
    .required(text?.validations?.age_is_required[lang])
    .test(
      "is-two-digit",
      "Age must be a two-digit number",
      (value) => value == null || (value >= 18 && value <= 60)
    ),
  gender: Yup.string()
    .oneOf(["male", "female", "other"], text?.validations?.invalid_gender[lang])
    .required(text?.validations?.gender_is_required[lang]),
});

const ParticipantProfileUpdateScreen = ({
  onProfileUpdateSuccess,
  onPrticipantDetailsUpdate,
  setNetworkError,
}) => {
  const navigate = useNavigate();
  const [isProfileUpdated, setIsProfileUpdated] = useState(false);
  const {
    authData,
    setProfile,
    updateProfileStatus,
    decodedData,
    participantStatus,
    signOut,
  } = useAuth();
  const [isVerifying, setIsVerifying] = useState(false);
  const idToken = authData?.data?.tokenresponse?.IdToken;
  const accessToken = authData?.data?.tokenresponse?.AccessToken;
  const [loading, setLoading] = useState(false);
  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  };

  useEffect(() => {
    const fetchData = async () => {
      const idToken =
        authData?.data?.tokenresponse?.IdToken ||
        localStorage.getItem("id_token");
      const accessToken =
        authData?.data?.tokenresponse?.AccessToken ||
        localStorage.getItem("access_token");

      if (!accessToken || !idToken) {
        console.error("Tokens are not available");
        return;
      }

      const config = {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          id_token: idToken,
        },
      };

      try {
        const response = await axios.get(participantProfileView, config);
        if (response) {
          onProfileUpdateSuccess();
        }
        setProfile(response.data);
        setIsProfileUpdated(false);
        localStorage.setItem("buttondisabled", "false");
      } catch (error) {
        if (
          error.response?.data.message.includes(
            constants.ACCESS_TOKEN_IS_INVALID
          )
        ) {
          signOut();
        } else {
          console.error("Error fetching participants data:", error);
        }
      }
    };
    fetchData();
  }, [isProfileUpdated]);

  const formik = useFormik({
    initialValues: {
      firstName: localStorage.getItem("firstName") || "",
      age: localStorage.getItem("age") || "",
      gender: localStorage.getItem("gender") || "",
    },
    validationSchema,
    onSubmit: async (values) => {
      setIsVerifying(true);
      localStorage.setItem("verifying", "true");
      try {
        const payload = {
          gender: values.gender,
          age: values.age.toString(),
        };

        const config = {
          method: "post",
          url: updateProfile,
          headers: {
            Authorization: `Bearer ${accessToken}`,
            id_token: idToken,
          },
          data: payload,
        };

        toast.dismiss("updateProfileToast");
        const response = await axios.request(config);

        if (response.data.success) {
          updateProfileStatus(response.data);
          setIsProfileUpdated(true);
          onPrticipantDetailsUpdate();
          navigate("/participant-profile-view");
          toast.success(text?.toast?.profile_updated_successfully[lang], {
            toastId: "updateProfileToast",
            autoClose: 5000,
          });
        } else {
          // Handle unsuccessful update
          setIsProfileUpdated(false);
          toast.error(text?.toast?.failed_to_update_profile[lang], {
            toastId: "updateProfileToast",
            autoClose: 5000,
          });
        }
      } catch (error) {
        // Handle Axios or other errors
        console.error("Error updating profile:", error);

        if (error.message.includes("Network Error")) {
          setNetworkError(true);
          localStorage.setItem("buttondisabled", "true");
        } else {
          toast.error(text?.toast?.failed_to_update_profile[lang], {
            toastId: "updateProfileToast",
            autoClose: 7000,
          });
        }
        setIsProfileUpdated(false);
        setLoading(false);
        setIsVerifying(false);
        localStorage.setItem("verifying", "false");
      } finally {
        setLoading(false);
      }
    },
  });

  useEffect(() => {
    localStorage.setItem("age", formik.values.age);
    localStorage.setItem("gender", formik.values.gender);
  }, [formik.values]);

  // Check for values in local storage and handle form validation accordingly
  const hasLocalStorageValues =
    localStorage.getItem("age") && localStorage.getItem("gender");
  const isVerifyingFromLocalStorage =
    localStorage.getItem("verifying") === "true";
  const isButtonDisabled = localStorage.getItem("buttondisabled") === "true";

  return (
    <div className={styles.updateProfileContainer}>
      {!participantStatus["Mobile Number Verified"] || loading ? (
        <div className={styles.overlay}>
          <ThreeDotsLoader color="#000" />
        </div>
      ) : (
        <>
          <h1 className={styles.profileTitle}>{text?.update_profile[lang]}</h1>
          <form onSubmit={formik.handleSubmit}>
            <div className={styles.formGroup}>
              <label htmlFor="firstName" className={styles.firstNameLabel}>
                {text?.first_name[lang]}
              </label>
              <input
                type="text"
                id="firstName"
                name="firstName"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={capitalizeFirstLetter(decodedData?.name)}
                className={styles.firstNameField}
                readOnly
              />
              {formik.touched.firstName && formik.errors.firstName && (
                <div className={styles.errorMsg}>{formik.errors.firstName}</div>
              )}
            </div>

            <div className={styles.formRow}>
              <div
                className={styles.formGroup}
                style={{ flexShrink: isMobile ? "1" : "1.9" }}
              >
                <label htmlFor="age" className={styles.ageLabel}>
                  {text?.age[lang]}
                </label>
                <input
                  type="text"
                  id="age"
                  name="age"
                  onChange={(event) => {
                    const { value } = event.target;
                    if (
                      value === "" ||
                      (/^\d{1,2}$/.test(value) && parseInt(value, 10) <= 99)
                    ) {
                      formik.setFieldValue("age", value);
                    }
                  }}
                  onBlur={formik.handleBlur}
                  value={formik.values.age}
                  className={`
                    ${styles.ageInput} 
                    ${
                      formik.touched.age && formik.errors.age
                        ? styles.errorInput
                        : ""
                    } 
                    ${
                      isVerifying || isVerifyingFromLocalStorage
                        ? styles.disabledField
                        : ""
                    }
                  `}
                  placeholder={
                    isMobile ? text?.age[lang] : text?.enter_age[lang]
                  }
                  disabled={isVerifying || isVerifyingFromLocalStorage}
                />

                {formik.touched.age && formik.errors.age && (
                  <div className={styles.errorMsg}>{formik.errors.age}</div>
                )}
              </div>
              <div className={styles.formGroup}>
                <label htmlFor="gender" className={styles.genderLabel}>
                  {text?.gender[lang]}
                </label>
                <FormControl
                  sx={{
                    width: "100%",
                    flexShrink: "11",
                    ".MuiOutlinedInput-root": {
                      "& .MuiOutlinedInput-notchedOutline": {
                        border: "none", // Remove the border
                      },
                      "&:hover .MuiOutlinedInput-notchedOutline": {
                        border: "none", // Remove the border on hover
                      },
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        border: "none", // Remove the border on focus
                      },
                    },
                  }}
                >
                  <Select
                    id="gender"
                    name="gender"
                    value={formik.values.gender}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    displayEmpty
                    className={
                      isVerifying || isVerifyingFromLocalStorage
                        ? `${styles.selectField} ${styles.disabledInput}`
                        : formik.touched.gender && formik.errors.gender
                        ? `${styles.selectField} ${styles.errorInput}`
                        : styles.selectField
                    }
                    IconComponent={KeyboardArrowDownOutlinedIcon} // Use the imported icon here
                    sx={{
                      ".MuiSelect-select": {
                        color: formik.values.gender ? "inherit" : "#a2a2a2",
                        padding: 0,
                        fontFamily: "Brown, sans-serif",
                        fontSize: "16px",
                        "&&:before, &&:after": {
                          borderBottom: "none",
                        },
                      },
                      ".MuiSelect-icon": {
                        color: "#5E5E5E",
                      },
                    }}
                    renderValue={
                      formik.values.gender !== ""
                        ? undefined
                        : () => (
                            <span className={styles.selectPlaceholder}>
                              {text?.select[lang]}
                            </span>
                          )
                    }
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "top",
                        horizontal: "left",
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left",
                      },
                      classes: { paper: styles.dropdownStyle },
                    }}
                    disabled={isVerifying || isVerifyingFromLocalStorage}
                  >
                    <MenuItem className={styles.menuItemLabel} value="male">
                      {text?.male[lang]}
                    </MenuItem>
                    <MenuItem className={styles.menuItemLabel} value="female">
                      {text?.female[lang]}
                    </MenuItem>
                    <MenuItem className={styles.menuItemLabel} value="other">
                      {text?.non_binary[lang]}
                    </MenuItem>
                  </Select>
                </FormControl>
                {formik.touched.gender && formik.errors.gender && (
                  <div className={styles.errorMsg}>{formik.errors.gender}</div>
                )}
              </div>
            </div>
            <div className={styles.buttonStyle}>
              <button
                type="submit"
                className={`${styles.otpButtonActive} ${
                  isVerifying ||
                  !hasLocalStorageValues ||
                  isVerifyingFromLocalStorage ||
                  isButtonDisabled ||
                  Object.keys(formik.errors).length !== 0
                    ? styles.otpButtonDisabled
                    : ""
                }`}
                disabled={
                  isVerifying ||
                  !hasLocalStorageValues ||
                  isVerifyingFromLocalStorage ||
                  isButtonDisabled ||
                  Object.keys(formik.errors).length !== 0
                }
              >
                {isVerifying || isVerifyingFromLocalStorage ? (
                  <ThreeDots
                    height="20" // Adjust size as needed
                    width="30" // Adjust size as needed
                    color="#fff" // Set to gray as per your requirement
                    ariaLabel="loading"
                  />
                ) : (
                  text?.save[lang]
                )}
              </button>
            </div>
          </form>
        </>
      )}
    </div>
  );
};

export default ParticipantProfileUpdateScreen;
