import React from "react";

const VRIconBlack = ({ className }) => (
  <svg
    width="22"
    height="17"
    viewBox="0 0 22 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M11.0003 1.716C13.5537 1.716 15.8486 2.59738 17.3941 4.18275C18.6653 4.34971 19.8646 4.86884 20.8563 5.6815C19.1225 2.03775 15.531 0 11.0003 0C6.53158 0 2.91533 2.035 1.16083 5.66775C2.07002 4.92733 3.15348 4.43175 4.3082 4.22813C5.82483 2.574 8.21458 1.716 11.0003 1.716Z"
      fill="#363636"
    />
    <path
      d="M16.5003 16.5C15.0699 16.4999 13.6959 15.9426 12.6696 14.9462L12.6668 14.9435C12.5484 14.8544 12.4212 14.7774 12.2873 14.7139C11.8838 14.5284 11.4444 14.434 11.0003 14.4375C10.4311 14.4375 9.99795 14.5791 9.7147 14.7139C9.58031 14.7774 9.4527 14.8543 9.33382 14.9435L9.33107 14.9462C8.68457 15.5739 7.89377 16.0331 7.0282 16.2834C6.16263 16.5338 5.24881 16.5677 4.36708 16.3821C3.48536 16.1965 2.66273 15.7971 1.97155 15.219C1.28036 14.6409 0.741778 13.9019 0.403159 13.0669C0.0645398 12.2319 -0.063745 11.3265 0.0295842 10.4303C0.122913 9.53412 0.434998 8.67457 0.938397 7.92725C1.4418 7.17994 2.12109 6.56775 2.91655 6.14451C3.71201 5.72127 4.59927 5.49995 5.50032 5.5H16.5003C17.959 5.5 19.358 6.07946 20.3894 7.11091C21.4209 8.14236 22.0003 9.54131 22.0003 11C22.0003 12.4587 21.4209 13.8576 20.3894 14.8891C19.358 15.9205 17.959 16.5 16.5003 16.5Z"
      fill="#363636"
    />
  </svg>
);

export default VRIconBlack;
