import React from "react";
import styles from "./ProgressModal.module.css";
import ProfileIcon from "../../../assets/svgicons/ProfileIcon";
import VRIconBlack from "../../../assets/svgicons/VRIconBlack";
import CloseIcon from "../../../assets/svgicons/CloseIcon";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { text } from "../../../constant/text";
import { lang } from "../../../constant/Var";

const ProgressModal = ({
  isOpen,
  onClose,
  participantStatus,
  inProgressStatus,
}) => {
  if (!isOpen) return null;

  return (
    <div className={styles.modalOverlay}>
      <div className={styles.modalContent}>
        <div className={styles.profileInfoContainer}>
          <div className={styles.profileIconWrapper}>
            <div className={styles.backgroundForProfileIcon}>
              <ProfileIcon />
            </div>
            <div className={styles.statusWrapperContainer}>
              <div className={styles.closeIconWrapper} onClick={onClose}>
                <CloseIcon className={styles.closeIcon} onClick={onClose} />
              </div>
              <div className={styles.statusTextWrapper}>
                {participantStatus["Mobile Number Verified"] ? (
                  <CheckCircleIcon
                    className={styles.statusIcon}
                    sx={{ fontSize: "24px", color: "#016f4a" }}
                  />
                ) : (
                  <div className={styles.inprogress}>
                    <span className={styles.inProgresslabelText}>
                      {text?.numbers["1"]?.[lang]}
                    </span>
                  </div>
                )}
                <span className={styles.verified}>
                  {text?.status["Mobile Number Verified"]?.[lang]}
                </span>
              </div>
              <div className={styles.verticalLine}></div>
              <div className={styles.statusTextWrapper}>
                {participantStatus["Profile Information"] ? (
                  <CheckCircleIcon
                    className={styles.statusIcon}
                    sx={{ fontSize: "24px", color: "#016f4a" }}
                  />
                ) : (
                  <div
                    className={
                      inProgressStatus === 2
                        ? styles.inprogress
                        : styles.untouchedProgressIcon
                    }
                  >
                    <span
                      className={
                        inProgressStatus === 2
                          ? styles.inProgresslabelText
                          : styles.unTouchedLabelText
                      }
                    >
                      {text?.numbers["2"]?.[lang]}
                    </span>
                  </div>
                )}
                <span
                  className={
                    participantStatus["Profile Information"]
                      ? styles.verified
                      : inProgressStatus === 2
                      ? styles.inprogressText
                      : styles.unTouchedText
                  }
                >
                  {text?.status["Profile Completed"]?.[lang]}
                </span>
              </div>
            </div>
          </div>
          <div className={styles.horizontalLine}></div>
          <div className={styles.vrIconWrapper}>
            <div className={styles.backgroundForVrIcon}>
              <VRIconBlack />
            </div>
            <div>
              <div className={styles.statusWrapperContainer}>
                <div className={styles.statusTextWrapper}>
                  {participantStatus["Diagnostics Completed"] ? (
                    <CheckCircleIcon
                      className={styles.statusIcon}
                      sx={{ fontSize: "24px", color: "#016f4a" }}
                    />
                  ) : (
                    <div
                      className={
                        inProgressStatus === 3
                          ? styles.inprogress
                          : styles.untouchedProgressIcon
                      }
                    >
                      <span
                        className={
                          inProgressStatus === 3
                            ? styles.inProgresslabelText
                            : styles.unTouchedLabelText
                        }
                      >
                        {text?.numbers["3"]?.[lang]}
                      </span>
                    </div>
                  )}

                  <span
                    className={
                      participantStatus["Diagnostics Completed"]
                        ? styles.verified
                        : inProgressStatus === 3
                        ? styles.inprogressText
                        : styles.unTouchedText
                    }
                  >
                    {text?.status["Diagnostics Completed"]?.[lang]}
                  </span>
                </div>
                <div className={styles.verticalLine}></div>
                <div className={styles.statusTextWrapper}>
                  {participantStatus["Experience Completed"] ? (
                    <CheckCircleIcon
                      className={styles.statusIcon}
                      sx={{ fontSize: "24px", color: "#016f4a" }}
                    />
                  ) : (
                    <div
                      className={
                        inProgressStatus === 4
                          ? styles.inprogress
                          : styles.untouchedProgressIcon
                      }
                    >
                      <span
                        className={
                          inProgressStatus === 4
                            ? styles.inProgresslabelText
                            : styles.unTouchedLabelText
                        }
                      >
                        {text?.numbers["4"]?.[lang]}
                      </span>
                    </div>
                  )}
                  <span
                    className={
                      participantStatus["Experience Completed"]
                        ? styles.verified
                        : inProgressStatus === 4
                        ? styles.inprogressText
                        : styles.unTouchedText
                    }
                  >
                    {text?.status["Experience Completed"]?.[lang]}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProgressModal;
