import React from "react";

const DropDownLight = ({styles}) => (
  <svg
    width="12"
    height="7"
    viewBox="0 0 12 7"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={styles}
  >
    <path
      d="M10.1673 1.33333L6.00065 5.5L1.83398 1.33333"
      stroke="#5E5E5E"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export default DropDownLight;
