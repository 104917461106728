// UserDetailsTable.js
import React, { useEffect, useState } from "react";
import styles from "./UserDetailsTable.module.css";
import Switch from "react-switch";
import axios from "axios";
import { inactiveUser, listUser } from "../../apiRoutes/adminApi/admin-api";
import { refreshToken } from "../../apiRoutes/participantApi/participant-api";
import Pagination from "@mui/material/Pagination";
import { useAuth } from "../../../context/AuthContext";
import DeactivateModal from "../../../utils/Modals/AdminModal/DeactivateModal/DeactivateModal";
import { ThreeDots } from "react-loader-spinner";
import { Tooltip, Typography } from "@mui/material";
import { useImageAssets } from "../../../context/ImageAssetsContext";
import { lang, refershTokenTime } from "../../../constant/Var";
import constants from "../../../constants";
import { text } from "../../../constant/text";
import Banner from "../../../sharedComponents/Banner/Banner";

const isMobile = window.matchMedia("(max-width: 767px)").matches;

const UserDetailsTable = ({ isUserCreated }) => {
  const [userData, setUserData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(() => {
    const width = window.innerWidth;
    if (width >= 344 && width <= 767) {
      return 14;
    } else if (width >= 768 && width <= 1024) {
      return 19;
    } else {
      return 9;
    }
  });

  const [userUpdated, setUserUpdated] = useState(false);
  const { selectedFilters, userCreateSuccess, signOut, authData, setAuth } =
    useAuth();
  const [isDeactivateModalOpen, setIsDeactivateModalOpen] = useState(false);
  const [selectedUserForDeactivation, setSelectedUserForDeactivation] =
    useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const { imageAssetsData, loading } = useImageAssets();
  const [showFullNames, setShowFullNames] = useState([]);
  const [networkError, setNetworkError] = useState(false);

  // Function to handle touch start to show full name
  const handleTouchStart = (index) => {
    setShowFullNames((prev) => [...prev, index]);
  };

  // Function to handle touch end to revert to truncated name
  const handleTouchEnd = (index) => {
    setShowFullNames((prev) => prev.filter((i) => i !== index));
  };

  const fetchData = async (pageno) => {
    try {
      const userRoles =
        selectedFilters.roles?.length > 0 ? selectedFilters.roles : [];
      const userStatus =
        selectedFilters.statuses && selectedFilters.statuses?.length > 0
          ? selectedFilters.statuses
          : [];

      const idToken =
        authData?.data?.tokenresponse?.IdToken ||
        localStorage.getItem("id_token");
      const accessToken =
        authData?.data?.tokenresponse?.AccessToken ||
        localStorage.getItem("access_token");

      if (!accessToken || !idToken) {
        console.error("Tokens are not available");
        return;
      }

      const config = {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          id_token: idToken,
        },
      };
      const response = await axios.post(
        listUser,
        {
          userName: selectedFilters.inputValue || null,
          userrole: userRoles,
          pageNumber: pageno || currentPage,
          pageSize: pageSize,
          userstatus: userStatus,
        },
        config
      );

      setUserData(response.data.data || []);
      setTotalPages(Math.ceil(response.data.record_count / pageSize));
    } catch (error) {
      if (error.response && error.response.data) {
        if (
          error.response.data.message.includes(
            constants.ACCESS_TOKEN_IS_INVALID
          )
        ) {
          signOut();
        } else {
          console.error("Error fetching user data:", error.response.data);
        }
      } else if (error.message.includes("Network Error")) {
        setNetworkError(true)
      } else {
        console.error("Error fetching user data:", error);
      }
    } finally {
      setIsLoading(false); // Set isLoading to false after API call is complete
    }
  };

  // Reset currentPage to 1 whenever filters are applied or removed
  useEffect(() => {
    setCurrentPage(1);
    fetchData(1);
  }, [
    isUserCreated,
    userCreateSuccess,
    selectedFilters.roles,
    selectedFilters.statuses,
    selectedFilters.inputValue,
  ]);

  //Refreshing Token
  useEffect(() => {
    const config = {
      method: "post",
      url: refreshToken,
      headers: {
        refresh_token: authData?.data?.tokenresponse?.RefreshToken,
      },
    };
    const refreshAuthToken = async () => {
      try {
        const response = await axios(config);
        if (response.data && response.data.success) {
          const updatedAuthData = {
            ...authData,
            data: {
              ...authData.data,
              tokenresponse: {
                ...authData.data.tokenresponse,
                AccessToken: response.data.data.AccessToken,
                IdToken: response.data.data.IdToken,
              },
            },
          };
          setAuth(updatedAuthData);
        } else {
          console.error("Failed to refresh token:", response.data);
        }
      } catch (error) {
        console.log(error)
        if (
          error.response?.data?.message.includes(
            constants.FAILED_TO_GENERATE_TOKEN
          )
        ) {
          signOut();
        } else {
          console.error("Error refreshing token:", error);
        }
      }
    };

    fetchData();
    refreshAuthToken();
    const intervalId = setInterval(refreshAuthToken, refershTokenTime);
    return () => clearInterval(intervalId);
  }, [currentPage, userUpdated]);

  const handleToggle = (checked, id, userHashKey) => {
    setIsDeactivateModalOpen(true);
    setSelectedUserForDeactivation({ id, userHashKey, checked });
  };

  const handleDeactivateConfirm = async () => {
    if (selectedUserForDeactivation) {
      try {
        const idToken =
          authData?.data?.tokenresponse?.IdToken ||
          localStorage.getItem("id_token");
        const accessToken =
          authData?.data?.tokenresponse?.AccessToken ||
          localStorage.getItem("access_token");

        if (!accessToken || !idToken) {
          console.error("Tokens are not available");
          return;
        }

        const config = {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            id_token: idToken,
          },
        };

        const response = await axios.post(
          inactiveUser,
          {
            userhashkey: selectedUserForDeactivation.userHashKey,
          },
          config
        );
        if (response.data.success) {
          const newStatus = selectedUserForDeactivation.checked
            ? "Inactive"
            : "Active";
          setUserData((prevData) =>
            prevData.map((user) =>
              user.useridentifier === selectedUserForDeactivation.id
                ? { ...user, userstatus: newStatus }
                : user
            )
          );
          setUserUpdated(!userUpdated);
        } else {
          throw new Error("Failed to update user status");
        }
      } catch (error) {
        if (
          error.response?.data?.message.includes(
            constants.ACCESS_TOKEN_IS_INVALID
          )
        ) {
          signOut();
        } else if (error.message.includes("Network Error")) {
          setNetworkError(true)
        } else {
          console.error("Error updating user status:", error);
        }
      }
      setIsDeactivateModalOpen(false);
    }
  };

  const handleDeactivateCancel = () => {
    setIsDeactivateModalOpen(false);
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const renderPagination = () => {
    return (
      <div className={styles.paginationContainer}>
        <Typography className={styles.pageText}>{text?.dashboard?.page[lang]}</Typography>
        <Pagination
          count={totalPages}
          page={currentPage}
          onChange={handlePageChange}
          shape="rounded"
          siblingCount={0} // siblings count set to 0
        />
      </div>
    );
  };

  const handleRefresh = () => {
    window.location.reload();
  };

  const renderTableHeader = () => (
    <div className={styles.tableHeaderWrapper}>
      {networkError && <Banner message={text?.validations?.something_went_wrong_refresh[lang]} onRefresh={handleRefresh} action={text?.refresh[lang]}/>}
      <div className={styles.tableHeader}>
        <div className={styles.userIdHeader}>{text?.dashboard?.user_id[lang]}</div>
        <div className={styles.detailsHeader}>
          <div>{text?.first_name[lang]}</div>
          <div>{text?.role[lang]}</div>
          <div>{text?.status_text[lang]}</div>
          <div>{text?.dashboard?.actions[lang]}</div>
        </div>
      </div>
    </div>
  );

  if (isLoading) {
    // Render loading indicator while API call is in progress
    return (
      <div className={styles.noUsersContainer}>
        <ThreeDots height="40" width="50" color="#ebc03f" ariaLabel="loading" />
      </div>
    );
  }

  if (!isLoading && userData?.length === 0) {
    // Render "No Users" message only after API call is complete and if there's no data
    return (
      <>
        {renderTableHeader()}
        <div className={styles.noUsersContainer}>
          <img
            src={imageAssetsData["noResultFoundicon.png"]}
            alt="No Results"
            draggable="false"
          />
          <h2 className={styles.noUserHeading}>{text?.dashboard?.no_results_found[lang]}</h2>
          <p className={styles.noUserDesc}>
          {text?.dashboard?.we_coundnt_find[lang]}
            <br />
            {text?.dashboard?.please_try_again[lang]}
          </p>
        </div>
      </>
    );
  }

  return (
    <div className={styles.userDetailsTable}>
      {networkError && <Banner message={text?.validations?.something_went_wrong_refresh[lang]} onRefresh={handleRefresh} action={text?.refresh[lang]}/>}
      <div className={styles.tableHeaderWrapper}>
        <div className={styles.tableHeader}>
        <div className={styles.userIdHeader}>{text?.dashboard?.user_id[lang]}</div>
        <div className={styles.detailsHeader}>
          <div>{text?.first_name[lang]}</div>
          <div>{text?.role[lang]}</div>
          <div>{text?.status_text[lang]}</div>
          <div>{text?.dashboard?.actions[lang]}</div>
        </div>
        </div>

        {userData?.map((user, index) => (
          <div key={index} className={styles.tableRow}>
            <div className={styles.userId}>{user.useridentifier}</div>
            <div className={styles.userDetails}>
              <div className={styles.nameBox}>
                {isMobile ? (
                  <Tooltip
                    title={showFullNames.includes(index) ? user.username : ""}
                    placement="top"
                  >
                    <span
                      onTouchStart={() => handleTouchStart(index)}
                      onTouchEnd={() => handleTouchEnd(index)}
                      onTouchCancel={() => handleTouchEnd(index)} // Handle touch cancel to revert on abrupt touch end
                    >
                      {user.username?.length > 5
                        ? user.username.slice(0, 5) + "..."
                        : user.username || "--"}
                    </span>
                  </Tooltip>
                ) : (
                  <Tooltip
                    title={user.username?.length > 10 ? user.username : null}
                  >
                    {user.username?.length > (isMobile ? 5 : 10)
                      ? user.username.slice(0, isMobile ? 5 : 10) + "..."
                      : user.username}
                  </Tooltip>
                )}
              </div>
              <div>{text?.roles[user.userrole]?.[lang]}</div>
              <div
                className={
                  user.userstatus.trim().toLowerCase() === "inactive"
                    ? styles.inactiveStatus
                    : ""
                }
              >
                {user.userstatus.toLowerCase() === "active"? text?.active[lang] : text?.inactive[lang]}
              </div>
              <div className={styles.userActions}>
                <Switch
                  onChange={(checked) =>
                    handleToggle(checked, user.useridentifier, user.userhashkey)
                  }
                  checked={user.userstatus.trim().toLowerCase() === "active"}
                  offColor="#686868"
                  onColor="#016F4A"
                  uncheckedIcon={false}
                  checkedIcon={false}
                  height={20}
                  width={40}
                  className="react-switch"
                  id="material-switch"
                  disabled={user.userstatus.trim().toLowerCase() !== "active"}
                />
              </div>
            </div>
          </div>
        ))}
      </div>
      <div>{renderPagination()}</div>

      <DeactivateModal
        isOpen={isDeactivateModalOpen}
        onCancel={handleDeactivateCancel}
        onConfirm={handleDeactivateConfirm}
        msg={text?.dashboard?.user_deactivate_confirmation[lang]}
        userid={
          selectedUserForDeactivation ? selectedUserForDeactivation.id : ""
        }
      />
    </div>
  );
};

export default UserDetailsTable;
