import React, { useState, useRef, useEffect } from "react";
import styles from "./EmailOtpComponent.module.css";
import { useNavigate } from "react-router-dom";
import { ADMIN_SIGNIN_MESSAGE } from "../../apiRoutes/adminRoutes/adminRoutes";
import axios from "axios";
import { loginUser, signInUser } from "../../apiRoutes/adminApi/admin-api";
import { useAuth } from "../../../context/AuthContext";
import { ThreeDots } from "react-loader-spinner";
import { login } from "../../../utils/AuthFile/Auth";
import { text } from "../../../constant/text";
import { lang } from "../../../constant/Var";

const EmailOtpComponent = ({ email, emailValid }) => {
  const [otp, setOtp] = useState(new Array(6).fill(""));
  const [isOtpComplete, setIsOtpComplete] = useState(false);
  const [timeLeft, setTimeLeft] = useState(59);
  const inputsRef = useRef([]);
  const navigate = useNavigate();
  const { setAdminAuth, setAdminOtpVerified } = useAuth();
  const [isVerifying, setIsVerifying] = useState(false);
  const [isOtpError, setIsOtpError] = useState(false);
  const [isNetworkError, setIsNetworkError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { setAuth, loginInfo } = useAuth();
  const [isInputDisabled, setIsInputDisabled] = useState(false);
  const [showRegenerate, setShowRegenerate] = useState(false);
  const [isAccountDisabled, setIsAccountDisabled] = useState(false);

  useEffect(() => {
    // Timer logic remains the same
    window.timer = setInterval(() => {
      setIsInputDisabled(false);
      const currentTime= Math.floor(new Date().getTime() / 1000);
      const startingTime=localStorage.getItem("timer")
      setTimeLeft((prevTime) => {
        if (prevTime <= 1) {
          clearInterval(window.timer);
          setShowRegenerate(true);
          setIsInputDisabled(true);
          return 0;
        }
        return startingTime - currentTime;
      });
    }, 1000);

    // Cleanup interval on component unmount
    return () => clearInterval(window.timer);
  }, [timeLeft]);

  useEffect(() => {
    // OTP completion check remains the same
    setIsOtpComplete(otp.every((digit) => digit.trim() !== ""));
  }, [otp]);

  useEffect(()=>{
    const currentTime= Math.floor(new Date().getTime() / 1000);
    localStorage.setItem("timer",currentTime+59)
  },[])

  const handleChange = (element, index) => {
    if (isNaN(element.value)) return false; // Check if the value is a number
    if (!/^\d$/.test(element.value)) {
      // Check if the value is a single digit
      return false;
    }
    setIsOtpError(false);
    setOtp((currentOtp) => {
      const newOtp = [...currentOtp]; // Define newOtp based on the current state
      newOtp[index] = element.value; // Update the value at the current index

      // Check if all OTP fields are filled after this update
      const isComplete = newOtp.every((digit) => digit.trim() !== "");
      setIsOtpComplete(isComplete); // Update the OTP completion state

      // Move focus to the next input if the current one is filled and it's not the last input
      if (element.nextSibling && element.value) {
        element.nextSibling.focus();
      }

      return newOtp; // Return the updated array to set the new state
    });
  };

  const handleKeyUp = (event, index) => {
    if (event.key === "Backspace") {
      const newOtp = [...otp];
      setIsOtpError(false);
      // If the current input is empty and backspace is pressed, move to the previous input and clear its value
      if (otp[index] === "") {
        if (index > 0) {
          newOtp[index - 1] = "";
          inputsRef.current[index - 1].focus();
        }
      } else {
        // If the current input is not empty, just clear its value
        newOtp[index] = "";
      }
      setOtp(newOtp); // Update the OTP state
    }
  };

  const handleResendClick = () => {
    console.log("Resend OTP logic should go here.");
    const resendOtpEndpoint = loginUser;
    const emailData = {
      email: email,
    };
    setIsLoading(true);
    setIsNetworkError(false)
    setIsOtpError(false); 
    axios
      .post(resendOtpEndpoint, emailData)
      .then((response) => {
        console.log("OTP resend successful:", response.data);
        localStorage.setItem(
          "super_admin_otp_session",
          response.data.data.login_response.data.Session
        );
        const currentTime= Math.floor(new Date().getTime() / 1000);
        localStorage.setItem("timer",currentTime+59)
        setTimeLeft(59);
        setIsOtpError(false); // Reset the OTP error state upon successful regeneration
        setOtp(new Array(6).fill("")); // Reset OTP fields
        setIsOtpComplete(false); // Reset OTP completion state
        setShowRegenerate(false)
        setIsLoading(false);
        setIsInputDisabled(false);
        inputsRef.current[0].focus();
      })
      .catch((error) => {
        console.error("OTP resend error:", error);
        if (error.message === "Network Error") {
          setShowRegenerate(true)
          setIsOtpError(false); 
          setIsNetworkError(true)
          setIsVerifying(false);
          setIsLoading(false);
        } else if (error.response.data.message.toLowerCase().includes("failed to generate token") || error.response.data.message.includes("Access is denied for this User !")){
          localStorage.removeItem("timer");
          setIsNetworkError(false)
          setIsAccountDisabled(true)
          setShowRegenerate(false);
          setIsInputDisabled(true);
          setIsLoading(false);
        } else{
          setIsInputDisabled(false);
          setIsLoading(false);
        }       
      });
  };

  const handleSignIn = () => {
    const fullOtp = otp.join("");
    const sessionId = localStorage.getItem("super_admin_otp_session");

    const requestBody = { email: email, otp: fullOtp, session: sessionId };
    setIsLoading(true);
    setIsNetworkError(false)
    // Clear the countdown timer
    clearInterval(window.timer);

    axios
      .post(signInUser, requestBody)
      .then((response) => {
        if (response?.data?.success) {
          setAdminOtpVerified(true);
          setAdminAuth(response.data);
          navigate(ADMIN_SIGNIN_MESSAGE);
          setIsOtpError(false); // Reset OTP error state on successful verification
          setIsLoading(false);
          login();
          loginInfo();
          setAuth(response.data);
          setIsInputDisabled(false);
          setShowRegenerate(true)
          localStorage.removeItem("timer");
        } else {
          setIsOtpError(true); // Set OTP error state if verification fails
          setIsVerifying(false);
          setIsInputDisabled(true);
        }
      })
      .catch((error) => {
        console.error("Sign In Error:", error);
        if (error.message === "Network Error") {
          setIsOtpError(false); 
          setIsNetworkError(true)
          setIsVerifying(false);
          setShowRegenerate(true)
        } else{
          setIsNetworkError(false)
          setIsOtpError(true); 
          setIsVerifying(false);
          setIsInputDisabled(true);
          setShowRegenerate(true)
        }  
        setIsLoading(false);
      });
  };

  return (
    <div>
      <div className={styles.otpInstructionAndTimer}>
        <span className={styles.otpInstructionText}>
          {text?.otp[lang]}
          <span className={styles.otpSubText}>({text?.as_sent_in_email[lang]})</span>
        </span>
        {!showRegenerate ? (
          <span className={styles.timer}>
            <span className={styles.resentText}>{text?.resend_otp[lang]} </span>
            <span className={styles.otpCount}>
              {text?.in[lang]}{" "}
              {`${Math.floor(timeLeft / 60)}:${timeLeft % 60 < 10 ? "0" : ""}${
                timeLeft % 60
              }`}{" "}
              {Math.floor(timeLeft / 60) > 0 ? "min" : "min"}
            </span>
          </span>
        ) : null}
      </div>
      <div className={styles.otpInputs}>
        {otp.map((digit, index) => (
          <input
            key={index}
            type="password"
            maxLength="1"
            value={digit}
            onChange={(e) => handleChange(e.target, index)}
            onKeyUp={(e) => handleKeyUp(e, index)}
            onFocus={(e) => e.target.select()}
            ref={(el) => (inputsRef.current[index] = el)}
            className={`${styles.otpInput} ${
              isOtpError ? styles.otpInputError : ""
            }`}
            autoFocus={index == 0 ? true : false}
            disabled={isOtpError || isInputDisabled||isLoading}
          />
        ))}
      </div>
      {isOtpError && <div className={styles.error}>{text?.validations.invalid_otp[lang]}</div>}
      {isNetworkError && <div className={styles.error}>{text?.validations.something_is_wrong[lang]}</div>}
      {isAccountDisabled && <div className={styles.error}>{text?.validations.account_is_disabled[lang]}</div>}

      <div className={styles.buttonContainer}>
        {isOtpError || showRegenerate || timeLeft <= 0 ? (
          <button
            onClick={handleResendClick}
            className={`${styles.resendButton} ${
              emailValid ? styles.resendButtonActive : ""
            }`}
            style={{
              justifyContent: "center",
              display: "flex",
              alignItems: "flex-end",
            }}
            disabled={!emailValid}
          >
            {isLoading ? (
              <ThreeDots
                height="20" // Adjust size as needed
                width="30" // Adjust size as needed
                color="#fff" // Set to gray as per your requirement
                ariaLabel="loading"
              />
            ) : (
              text?.regenerate_otp[lang]
            )}
          </button>
        ) : (
          <button
            onClick={handleSignIn}
            className={`${styles.signInButton} ${
              isOtpComplete && !isVerifying && !isAccountDisabled? styles.signInButtonActive : ""
            }`}
            disabled={!isOtpComplete || isVerifying || isAccountDisabled}
            style={{
              justifyContent: "center",
              display: "flex",
              alignItems: "flex-end",
            }}
          >
            {isLoading ? (
              <ThreeDots
                height="20" // Adjust size as needed
                width="30" // Adjust size as needed
                color="#fff" // Set to gray as per your requirement
                ariaLabel="loading"
              />
            ) : (
              text?.sign_in[lang]
            )}
          </button>
        )}
      </div>
    </div>
  );
};

export default EmailOtpComponent;
