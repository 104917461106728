import { countryData } from "./data";

// Helper function to extract phone prefix (assumes phone numbers start with '+')
const getPhonePrefix = (phone) => phone.split(' ')[0];

const seenPrefixes = new Set();
const uniqueCountries = countryData.filter(country => {
    const prefix = getPhonePrefix(country.phone);
    if (seenPrefixes.has(prefix)) {
        return false; // Skip this country, its prefix is already seen
    } else {
        seenPrefixes.add(prefix); // Mark this prefix as seen
        return true; // Include this country
    }
});

export const filteredArray = uniqueCountries.map(country => {
    const { code, label, ...rest } = country;
    return rest;
}).sort((a, b) => {
    // First, compare based on the length of the phone numbers to prioritize shorter numbers
    if (a.phone.length !== b.phone.length) {
        return a.phone.length - b.phone.length;
    }

    // If lengths are equal, then compare based on the first digit
    const firstDigitA = a.phone.charAt(0);
    const firstDigitB = b.phone.charAt(0);
    if (firstDigitA !== firstDigitB) {
        return firstDigitA.localeCompare(firstDigitB);
    }

    // If the first digits are the same, compare based on the second digit, defaulting to '0' if not present
    const secondDigitA = a.phone.charAt(1) || '0';
    const secondDigitB = b.phone.charAt(1) || '0';
    return secondDigitA.localeCompare(secondDigitB);
});


