import React, { useState, useEffect, useContext } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import { useInactivityDetector } from "../components/session-timeout/InactivityDetector";
import SessionTimeoutModal from "../utils/Modals/SessionTimeOutModal/SessionTimeoutModal";
import constants from "../constants";
import { RDP_DASHBOARD } from "../components/apiRoutes/rdpParticipantRoutes/rdpParticipantRoutes";
import { PARTICIPANT_PROFILE_VIEW } from "../components/apiRoutes/participantRoutes/participantsRoutes";
import {
  ADMIN_DASHBOARD,
  ADMIN_LOGIN,
  ADMIN_SIMULATIONS,
} from "../components/apiRoutes/adminRoutes/adminRoutes";
import { SimulationContext } from "../context/SimulationCotext";

const AdminProtectedRoutes = ({ children }) => {
  const navigate = useNavigate();
  const { resetSimulationState } = useContext(SimulationContext);
  const {
    isAdminLoginSuccessful,
    isAdminOtpVerified,
    signOut,
    role,
    adminInfo,
  } = useAuth();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const closeTimeKey = 'adminCloseTime'; 
  const modalOpenKey = 'adminIsModalOpen'; // Key for storing modal open state in localStorage

  useEffect(() => {
    if (isModalOpen) {
      setIsModalOpen(true);
      localStorage.setItem(modalOpenKey, "true"); // Store modal state in localStorage
    }
  }, [isModalOpen]);

  useEffect(() => {
    // Check if the modal was previously opened and restore its state
    if (localStorage.getItem("adminIsModalOpen") === "true") {
      setIsModalOpen(true);
    }
  }, []);

  useEffect(() => {
    const checkPreviousSession = () => {
      const closeTime = localStorage.getItem(closeTimeKey);
      if (closeTime) {
        const currentTime = new Date().getTime();
        const timePassed = (currentTime - parseInt(closeTime)) / 1000;
        if (timePassed > 1800) {
          localStorage.removeItem(closeTimeKey);
          if (isAdminLoginSuccessful && isAdminOtpVerified) {
            setIsModalOpen(true)
            navigate(ADMIN_LOGIN);
          }
        }
      }
    };

    // Save the current time to localStorage before the page unloads
    const handleBeforeUnload = () => {
      if (isAdminLoginSuccessful && isAdminOtpVerified) {
        const currentTime = new Date().getTime();
        localStorage.setItem(closeTimeKey, currentTime);
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    checkPreviousSession();

    // Check if modal state exists in localStorage
    if (localStorage.getItem(modalOpenKey) === "true") {
      setIsModalOpen(true);
    }

    if (role === constants.RDP) {
      navigate(RDP_DASHBOARD);
    } else if (role === constants.PARTICIPANT) {
      navigate(PARTICIPANT_PROFILE_VIEW);
    } else if (adminInfo.role === "Admin") {
      navigate(ADMIN_DASHBOARD);
    } else if (adminInfo.role === constants.URBAN_PLANNER) {
      navigate(ADMIN_SIMULATIONS);
    }

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      localStorage.removeItem(closeTimeKey);
      localStorage.removeItem(modalOpenKey);
    };
  }, [isAdminLoginSuccessful, isAdminOtpVerified, navigate, role, adminInfo]);

  const timeout = 1000 * 60 * 25; //25 minute

  const onInactive = () => {
    console.log("User has been inactive.");
    if (isAdminLoginSuccessful && isAdminOtpVerified) {
      console.log("Opening modal due to inactivity.");
      setIsModalOpen(true);
      localStorage.setItem(modalOpenKey, "true"); // Store modal state in localStorage
    }
  };

  useInactivityDetector(timeout, onInactive);

  const handleModalConfirm = () => {
    resetSimulationState();
    signOut();  
    localStorage.removeItem(closeTimeKey);
    localStorage.removeItem(modalOpenKey);
    setIsModalOpen(false);
  };

  if (!isAdminOtpVerified && !isAdminLoginSuccessful) {
    return <Navigate to={ADMIN_LOGIN} replace />;
  }

  return (
    <>
      {children}
      <SessionTimeoutModal
        isOpen={isModalOpen}
        onConfirm={handleModalConfirm}
      />
    </>
  );
};

export default AdminProtectedRoutes;
