import React, { useState, useEffect } from "react";
import ReactSelect, { components } from "react-select";
import { Checkbox } from "@mui/material";
import CircularUnchecked from "../../assets/svgicons/CircularUnchecked";
import Circlechecked from "../../assets/svgicons/CircleChecked";
import DropDownLight from "../../assets/svgicons/DropDownLight";
import { text } from "../../constant/text";
import { lang } from "../../constant/Var";

const Option = (props) => {
  // Intercept the onChange handler to prevent actual state changes
  const handleChange = (event) => {
    event.preventDefault();
  };

  return (
    <div>
      <components.Option {...props}>
        <Checkbox
          checked={props.isSelected}
          icon={<CircularUnchecked />}
          checkedIcon={<Circlechecked />}
          onChange={handleChange}
          color="default"
          inputProps={{ "aria-label": "Checkbox demo" }}
          style={{ marginBottom: 2, marginRight: 3 }}
        />
        <label>{props.label}</label>
      </components.Option>
    </div>
  );
};

const MultiValueRemove = (props) => {
  return (
    <components.MultiValueRemove {...props}>
      <span style={{ color: "#EBC03F" }}>&times;</span> 
    </components.MultiValueRemove>
  );
};

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <DropDownLight styles={{marginRight: 5}}/>
    </components.DropdownIndicator>
  );
};


const MenuList = (props) => {
  return (
    <components.MenuList {...props}>
      <div
        style={{
          padding: "8px 18px",
          fontWeight: 400,
          fontFamily: "Brown, sans-serif",
          fontSize: "12px",
          color: "#A2A2A2",
        }}
      >
        {/* Select age group */}
        {props.selectProps.menuHeaderText || "Select option"}
      </div>
      {props.children}
    </components.MenuList>
  );
};

const MultiValueContainer = ({ children, selectProps, ...props }) => {
  return (
    <components.MultiValueContainer {...props}>
      {children}
    </components.MultiValueContainer>
  );
};
const customStyles = {
  option: (provided, state) => ({
    ...provided,
    backgroundColor:
      state.isFocused || state.isSelected ? "transparent" : "transparent", // Ensure background remains transparent for options
    color: "#5E5E5E",
    cursor: "default",
    fontSize: "14px",
    fontFamily: "Brown, sans-serif",
    fontWeight: "400",
    margin:"0",
    ":active": {
      ...provided[":active"],
      backgroundColor: "transparent", // Ensuring active state has no background change
    },
  }),
  control: (provided, state) => ({
    ...provided,
    minHeight: "44px",
    fontSize: "14px",
    fontFamily: "Brown, sans-serif",
    fontWeight: "400",
    color: "red",
    borderColor: "#E5E5E5",
    boxShadow: state.isFocused ? "none" : provided.boxShadow, // Remove focus box shadow
    border: state.isFocused ? "1px solid #E5E5E5" : "1px solid #E5E5E5", // Keep the border color consistent
    "&:hover": {
      borderColor: "#E5E5E5", // Maintain the border color on hover
    },
    backgroundColor: "transparent",
  }),
  multiValue: (provided, state) => ({
    ...provided,
    backgroundColor: "#FCF8E8", // Set background color for selected values
    padding: "6px",
    overflow: "hidden",
    flexWrap: "nowrap",
    fontSize: "12px",
    fontFamily: "Brown, sans-serif",
    fontWeight: "400",
    borderRadius: "4px",
  }),
  multiValueLabel: (provided, state) => ({
    ...provided,
    whiteSpace: "nowrap",
    color: "#686868",
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    display: "flex",
    flexWrap: "wrap",
    overflowX: "auto",
    maxHeight: "44px",
    padding: "2px 3px"
  }),
  placeholder: (provided, state) => ({
    ...provided,
    marginLeft: "8px",  // Add left margin to the placeholder
    fontFamily: "Brown, sans-serif",
    fontSize: "14px",
    color: "#A2A2A2",
  }),
  menu: (provided, state) => ({
    ...provided,
    marginTop: '-10.5%',  // Adjust the margin to move the dropdown upwards
    width: '253px',
    marginLeft: '5px',
    border: '0', // Remove the border
    borderRadius: '8px',
    boxShadow: '0 8px 16px rgba(0, 0, 0, 0.1), 0 -4px 8px rgba(0, 0, 0, 0.05)', // Add shadow on all sides
  }),
};

const CustomValueContainer = ({ options, defaultValue, value, onChange, menuHeaderText }) => {
  const [optionSelected, setOptionSelected] = useState(defaultValue || options);

  useEffect(() => {
    setOptionSelected(defaultValue || options);
  }, [defaultValue, options]);

  const CustomClearIndicator = (props) => {
    if (props.selectProps.value.length > 0) {
      return null;
    }
    return <components.ClearIndicator {...props} />;
  };

  return (
    <div
      className="d-inline-block"
      data-toggle="popover"
      data-trigger="focus"
      data-content="Please select option(s)"
      style={{marginTop: 10}}
    >
      <ReactSelect
        options={options}
        isMulti
        closeMenuOnSelect={false}
        hideSelectedOptions={false}
        isSearchable={false}
        components={{
          Option,
          MultiValueContainer,
          MultiValueRemove,
          ClearIndicator: CustomClearIndicator,
          MenuList,
          DropdownIndicator,
        }}
        onChange={onChange} 
        value={value} 
        styles={customStyles} 
        placeholder={<div>{text?.select[lang]}...</div>}
        menuHeaderText={menuHeaderText}
      />
    </div>
  );
};

export default CustomValueContainer;
