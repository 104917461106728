import React, { useState, useRef, useEffect, useCallback } from "react";
import _ from "lodash";
import styles from "./SearchModal.module.css";
import { useAuth } from "../../../../context/AuthContext";
import magnifyGlass from "../../../../assets/svgicons/magnifyGlass.svg";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { text } from "../../../../constant/text";
import { lang } from "../../../../constant/Var";

const SearchModal = ({ isOpen, onClose }) => {
  const [stagedFilters, setStagedFilters] = useState({
    roles: [],
    statuses: [],
    inputValue: "",
  });
  const [filterText, setFilterText] = useState("");

  const modalRef = useRef();
  const {
    selectedFilters,
    setSelectedFilters,
  } = useAuth();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        onClose();
      }
    };

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose]);

  useEffect(() => {
    setStagedFilters({ ...selectedFilters });
  }, [selectedFilters]);

  // Define a debounced function to handle search input changes
  const debounceFuncUserFilter = useCallback(
    _.debounce((value) => {
      const obj = { ...stagedFilters, inputValue: value };
      setStagedFilters(obj);
      setSelectedFilters(obj);
    }, 500),
    [stagedFilters]
  );

  const handleFilterText = (value) => {
    const regex = /^[a-zA-Z0-9]+$/;
    if (value === "") {
      setFilterText(value);
      debounceFuncUserFilter(value);
    }
    // If the input is not empty, check the regex and length constraints
    else if (value && regex.test(value) && value.length <= 15) {
      setFilterText(value);
      debounceFuncUserFilter(value);
    }
  };

  if (!isOpen) return null;

  return (
    <div className={styles.overlay}>
      <div className={styles.modal} ref={modalRef}>
        <div className={styles.searchInputContainer}>
          <input
            className={styles.searchInput}
            type="text"
            placeholder={text?.search_by_uid_name[lang]}
            onChange={(e) => handleFilterText(e.target.value)}
            value={filterText}
          />
          {filterText && filterText?.length > 0 ? (
            <CloseRoundedIcon
              className={styles.clearIcon}
              fontSize="small"
              onClick={() => handleFilterText("")}
            />
          ) : (
            <img
              src={magnifyGlass}
              className={styles.magnifyGlassIcon}
              alt="X"
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default SearchModal;
