import { filteredArray } from "../../utils/data/countryData";
import "./index.scss";
import { useFormik } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import constants from "../../constants";
import { text } from "../../constant/text";
import { lang } from "../../constant/Var";

const PrimaryPhoneNumber = ({
  setFullPhoneNumber,
  id,
  isDisabled,
  selectedPhoneCode,
  setSelectedPhoneCode,
  phoneNumber,
  setPhoneNumber,
  verificationError,
  setVerificationError,
  responseError,
  setResponseMessage,
  responseMessage,
}) => {

  if (responseMessage == constants.EMAIL_ALREADY_EXISTS) {
    responseMessage = null;
  }
  const formik = useFormik({
    initialValues: {
      phoneNumber: "",
    },
    validationSchema: Yup.object({
      phoneNumber: Yup.string()
        .required(text?.validations.phone_number_required[lang])
        .matches(/^\+?\d{1,3}-?\d{1,15}$/, text?.validations.invalid_number_format[lang]),
    }),
    onSubmit: () => {},
  });

  const selectedPhoneCodeObject =
    filteredArray.find((item) => item.phone === selectedPhoneCode) || {};

  const options = filteredArray.map((item) => ({
    value: item.phone,
    label: `+${item.phone}`,
  }));

  const handlePhoneCodeChange = (selectedOption) => {
    setVerificationError("");
    setResponseMessage("");
    if (selectedOption) {
      const newPhoneCode = selectedOption.value;

      if (/^\+?[1-9]\d*-?\d*$/.test(newPhoneCode)) {
        setSelectedPhoneCode(newPhoneCode);
        setPhoneNumber("");
        localStorage.setItem("cc", newPhoneCode.length);
      } else {
      }
    } else {
      setSelectedPhoneCode("");
      setPhoneNumber("");
      setFullPhoneNumber("");
    }
  };

  const handleInputChange = (inputValue, actionMeta) => {
    if (actionMeta.action === "input-change") {
      const isValidInput = /^[\+\d-]{0,7}$/.test(inputValue);
      if (!isValidInput) {
        return "";
      }
      if (phoneNumber.length !== selectedPhoneCodeObject?.phoneLength) {
        setVerificationError("");
      } else {
        setVerificationError("");
      }
    }
    return inputValue;
  };

  const handlePhoneNumberChange = (event) => {
    if (!Boolean(selectedPhoneCode)) return;

    const inputNumber = event.target.value;

    // Reset the error message if the input is empty
    if (inputNumber === "") {
      setResponseMessage("");
      setPhoneNumber("");
      formik.setFieldValue("phoneNumber", `+${selectedPhoneCode}`);
      setFullPhoneNumber(`+${selectedPhoneCode}`);
      localStorage.setItem("pn", 0);
      setVerificationError(""); // Clear the verification error
      return;
    }

    // Only process valid numeric input
    if (/^\d*$/.test(inputNumber)) {
      setResponseMessage("");
      setPhoneNumber(inputNumber);
      formik.setFieldValue(
        "phoneNumber",
        `+${selectedPhoneCode}${inputNumber}`
      );
      setFullPhoneNumber(`+${selectedPhoneCode}${inputNumber}`);
      localStorage.setItem("pn", inputNumber.length);

      if (inputNumber.length !== selectedPhoneCodeObject?.phoneLength) {
        setVerificationError(text?.user_creation_errors.please_enter_valid_number[lang]);
      } else {
        setVerificationError("");
      }
    }
  };

  const handleKeyDown = (event) => {
    // Allow only numeric keys and certain control keys
    const allowedKeys = [
      "Backspace",
      "ArrowLeft",
      "ArrowRight",
      "ArrowUp",
      "ArrowDown",
      "Delete",
      "Tab",
      "Enter",
      "+",
      "-",
      "0",
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
    ];

    // Check if the pressed key is not in the allowed keys list
    if (!allowedKeys.includes(event.key)) {
      event.preventDefault(); // Prevent the key press
    }
  };

  return (
    <div className="container">
      <div>
        <Select
          className="dropdown"
          value={
            options.find((option) => option.value === selectedPhoneCode) || null
          }
          onChange={handlePhoneCodeChange}
          onInputChange={handleInputChange}
          options={options}
          isSearchable={true}
          placeholder={text?.select[lang]}
          id={id}
          isDisabled={isDisabled}
          components={
            isDisabled
              ? {
                  DropdownIndicator: () => (
                    <div style={{ padding: "1rem" }}></div>
                  ),
                  IndicatorSeparator: () => null,
                }
              : {}
          }
          styles={{
            control: (base, state) => ({
              ...base,
              paddingLeft: "0px", // Adjust this value to reduce the space
              paddingRight: "0px", // You can also adjust right padding if needed
              height: "48px",
              paddingRight: "0px",
              border: `${
                isDisabled ? "1px solid #e5e5e5" : "1px solid #5E5E5E"
              }`,
              background: `${isDisabled ? "#f9f9f9" : "white"}`,
              boxShadow: state.isFocused ? "0 0 0 1px #5E5E5E" : "",
              borderColor: state.isFocused ? "#5E5E5E" : base.borderColor,
              "&:hover": {
                borderColor: state.isFocused
                  ? "#5E5E5E"
                  : base["&:hover"].borderColor,
              },
            }),
            placeholder: (base) => ({
              ...base,
              color: "#A2A2A2",
              margin: "0px", // Ensures the placeholder text has no additional margin
              padding: 0, // Reduces any default padding around the placeholder
            }),
            input: (base) => ({
              ...base,
              margin: 0, // Reduces margin inside the input area to tighten the layout
              padding: 0, // Minimizes padding to allow the cursor to move closer
            }),
            dropdownIndicator: (base) => ({
              ...base,
              color: "#5E5E5E", // Change arrow color here
            }),
            indicatorSeparator: (base) => ({
              display: "none", // Hides the indicator separator
            }),
          }}
          onKeyDown={handleKeyDown}
        />
      </div>
      <div style={{ width: "100%" }}>
        <input
          className={`phoneNumberInput ${
            verificationError || formik.touched.phoneNumber || responseMessage
              ? "inputError"
              : ""
          }`}
          type="text"
          value={phoneNumber}
          onChange={handlePhoneNumberChange}
          maxLength={selectedPhoneCodeObject.phoneLength}
          placeholder={text?.enter_mobile_number[lang]}
          disabled={isDisabled}
        />
        {formik.errors.phoneNumber && formik.touched.phoneNumber && (
          <div className="error">{formik.errors.phoneNumber}</div>
        )}
        {(verificationError || responseError) && (
          <div className="error">{verificationError || responseError}</div>
        )}
      </div>
    </div>
  );
};

export default PrimaryPhoneNumber;
