import React from "react";

const ConfigureRecording = ({styles}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill="none"
    style={styles}
  >
    <path
      d="M10.5 13.125C11.9497 13.125 13.125 11.9497 13.125 10.5C13.125 9.05025 11.9497 7.875 10.5 7.875C9.05025 7.875 7.875 9.05025 7.875 10.5C7.875 11.9497 9.05025 13.125 10.5 13.125Z"
      fill="#686868"
    />
    <path
      d="M10.5 1.3125C5.44688 1.3125 1.3125 5.44688 1.3125 10.5C1.3125 15.5531 5.44688 19.6875 10.5 19.6875C15.5531 19.6875 19.6875 15.5531 19.6875 10.5C19.6875 5.44688 15.5531 1.3125 10.5 1.3125ZM10.5 14.4375C8.33437 14.4375 6.5625 12.6656 6.5625 10.5C6.5625 8.33437 8.33437 6.5625 10.5 6.5625C12.6656 6.5625 14.4375 8.33437 14.4375 10.5C14.4375 12.6656 12.6656 14.4375 10.5 14.4375Z"
      fill="#686868"
    />
  </svg>
);

export default ConfigureRecording;
