import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { AuthProvider } from "./context/AuthContext";
import "./style.css";
import { RDPProvider } from "./context/rdpContext";
import { ImageAssetsProvider } from "./context/ImageAssetsContext";
import { SimulationProvider } from "./context/SimulationCotext";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <AuthProvider>
    <RDPProvider>
      <ImageAssetsProvider>
        <SimulationProvider>
          <App />
        </SimulationProvider>
      </ImageAssetsProvider>
    </RDPProvider>
  </AuthProvider>
);

