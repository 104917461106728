import React, { useEffect, useRef, useState } from "react";
import CropSquareTwoToneIcon from "@mui/icons-material/CropSquareTwoTone";
import styles from "./RdpFilterModal.module.css";
import { useRDP } from "../../../../context/rdpContext";
import { Box, Checkbox, Popover, TextField } from "@mui/material";
import { useImageAssets } from "../../../../context/ImageAssetsContext";
import { text } from "../../../../constant/text";
import { lang } from "../../../../constant/Var";

const RdpFilterModal = ({ isOpen, onClose }) => {
  const { selectedFilters, setSelectedFilters } = useRDP();
  const [stagedFilters, setStagedFilters] = useState({ ...selectedFilters });
  const [ageError, setAgeError] = useState({ fromAge: false, toAge: false });
  const [anchorEl, setAnchorEl] = useState(null);
  const buttonRef = useRef(null);
  const open = Boolean(anchorEl);
  const { imageAssetsData } = useImageAssets();

  useEffect(() => {
    setStagedFilters({ ...selectedFilters });
  }, [selectedFilters]);

  const handleAgeChange = (field, value) => {
    // Handle the case where the input is cleared
    if (value === "") {
      setAgeError((prev) => ({ ...prev, [field]: false }));
      setStagedFilters((prev) => ({ ...prev, [field]: value }));

      // Check the other field to see if it has a value
      const otherField = field === "fromAge" ? "toAge" : "fromAge";
      if (stagedFilters[otherField] !== "") {
        setAgeError((prev) => ({ ...prev, emptyFieldError: true }));
      } else {
        setAgeError((prev) => ({ ...prev, emptyFieldError: false }));
      }
      return;
    }

    // Check if the input is one or two digits. If not, simply ignore the input.
    if (!/^\d{1,2}$/.test(value)) {
      return;
    }

    const numericValue = parseInt(value, 10);
    const fromAge =
      field === "fromAge"
        ? numericValue
        : parseInt(stagedFilters.fromAge || 0, 10);
    const toAge =
      field === "toAge" ? numericValue : parseInt(stagedFilters.toAge || 0, 10);

    if (numericValue < 18 || numericValue > 60) {
      setAgeError((prev) => ({
        ...prev,
        [field]: true,
        rangeError: false,
        emptyFieldError: false,
      }));
      setStagedFilters((prev) => ({ ...prev, [field]: value }));
    } else {
      let newErrors = { ...ageError, [field]: false };

      if (
        (field === "fromAge" &&
          value !== "" &&
          (stagedFilters.toAge === "" || stagedFilters.toAge === null)) ||
        (field === "toAge" &&
          value !== "" &&
          (stagedFilters.fromAge === "" || stagedFilters.fromAge === null))
      ) {
        newErrors.emptyFieldError = true;
        newErrors.rangeError = false;
      } else if (
        (field === "fromAge" && numericValue > toAge && !isNaN(toAge)) ||
        (field === "toAge" && numericValue < fromAge && !isNaN(fromAge))
      ) {
        newErrors.rangeError = true;
        newErrors.emptyFieldError = false;
      } else {
        newErrors.rangeError = false;
        newErrors.emptyFieldError = false;
      }

      setAgeError(newErrors);
      setStagedFilters((prev) => ({ ...prev, [field]: value }));
    }
  };

  const handleCheckboxChange = (filterValue) => {
    const isSelected = stagedFilters.gender.includes(filterValue);
    let updatedGender;

    if (isSelected) {
      updatedGender = stagedFilters.gender.filter((f) => f !== filterValue);
    } else {
      updatedGender = [...stagedFilters.gender, filterValue];
    }

    setStagedFilters({ ...stagedFilters, gender: updatedGender }); // Update the temporary staged filters state
  };

  const handleStatusCheckboxChange = (statusValue) => {
    const isSelected = stagedFilters.statuses.includes(statusValue);
    let updatedStatuses;

    if (isSelected) {
      updatedStatuses = stagedFilters.statuses.filter((s) => s !== statusValue);
    } else {
      updatedStatuses = [...stagedFilters.statuses, statusValue];
    }

    setStagedFilters({ ...stagedFilters, statuses: updatedStatuses }); // Update the staged filters state
  };

  const handleApplyFilters = () => {
    setSelectedFilters(stagedFilters); // Apply the staged filters
    console.log(selectedFilters);
    handleClose(true);
    console.log(stagedFilters);
  };

  useEffect(() => {
    // Set anchorEl when isOpen changes
    if (isOpen) {
      setAnchorEl(document.body); // Ensure anchorEl is set to the button that triggers the popover
    } else {
      setAnchorEl(null);
    }
  }, [isOpen, buttonRef]);

  // Simplified handleClose that works universally
  const handleClose = (apply) => {
    onClose();
    setAnchorEl(null);
    if (!apply) {
      // Reset the staged filters and errors if the popover is closed without applying
      setStagedFilters({ ...selectedFilters });
      setAgeError({ fromAge: false, toAge: false });
    }
  };

  const handleKeyPress = (event) => {
    const allowedKeys = ["Backspace", "Delete", "ArrowLeft", "ArrowRight"];
    if (
      !allowedKeys.includes(event.key) &&
      (event.key < "0" || event.key > "9")
    ) {
      event.preventDefault();
    }
  };

  return (
    <>
      {open && <div className={styles.overlay}></div>}
      <Popover
        id={"filter-popover"}
        open={isOpen}
        anchorEl={anchorEl}
        onClose={() => handleClose(false)}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        sx={{
          "& .MuiPaper-root": {
            // Targeting the Paper component inside Popover
            borderRadius: "12px", // Apply border-radius
            mt: "40%", // Margin top (you can adjust the value as needed)
            boxShadow: "0px 4px 10px 6px rgba(0, 0, 0, 0.1)", // Custom box shadow
          },
        }}
        slotProps={{
          paper: { sx: { borderRadius: "12px", width: "264px" } },
        }}
      >
        <div className={styles.filterContentWrapper}>
          <div className={styles.filterHeader}>{text?.filter_by[lang]} :</div>
          <div className={styles.filterCategory}>
            <div className={styles.filterTitle}>{text?.age[lang]}</div>
            <Box>
              <Box className={styles.ageBox}>
                <Box className="flex-align-center" sx={{ gap: 1 }}>
                  <label className={styles.fromLabel} htmlFor="From">
                  {text?.from[lang]}
                  </label>
                  <TextField
                    id="age-from"
                    label=""
                    variant="outlined"
                    type="text"
                    value={stagedFilters.fromAge}
                    onChange={(event) => {
                      let value = event.target.value;
                      if (value === "") {
                        handleAgeChange("fromAge", ""); // Allow clearing the input
                      } else {
                        value = Number(value);
                        if (value > 0) {
                          handleAgeChange("fromAge", value);
                        } else if (value === 0) {
                          handleAgeChange("fromAge", ""); // Start from 1 instead of 0
                        } else {
                          handleAgeChange("fromAge", "");
                        }
                      }
                    }}
                    size="small"
                    InputProps={{
                      sx: {
                        height: "30px",
                        width: "48px",
                        fontFamily: "Brown, sans-serif",
                      },
                      notchedOutline: { borderColor: "#E5E5E5" },
                    }}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: "#E5E5E5",
                        },
                        "&:hover fieldset": {
                          borderColor: "#E5E5E5",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "#E5E5E5",
                        },
                      },
                    }}
                    onKeyDown={(event) => {
                      // Prevent negative values and scientific notation ('e') from being typed
                      if (event.key === "-" || event.key === "e") {
                        event.preventDefault();
                      }
                      handleKeyPress(event);
                    }}
                    onDrop={(event) => {
                      const data = event.dataTransfer.getData("text");
                      if (
                        data.includes("e") ||
                        data.includes("-") ||
                        isNaN(Number(data))
                      ) {
                        event.preventDefault();
                      }
                    }}
                    onPaste={(event) => {
                      const data = event.clipboardData.getData("text");
                      if (
                        data.includes("e") ||
                        data.includes("-") ||
                        isNaN(Number(data))
                      ) {
                        event.preventDefault();
                      }
                    }}
                  />
                </Box>
                <Box className="flex-align-center" sx={{ gap: 1 }}>
                  <label className={styles.toLabel} htmlFor="To">
                  {text?.to[lang]}
                  </label>
                  <TextField
                    id="age-to"
                    label=""
                    variant="outlined"
                    type="text"
                    value={stagedFilters.toAge}
                    onChange={(event) => {
                      let value = event.target.value;
                      if (value === "") {
                        handleAgeChange("toAge", ""); // Allow clearing the input
                      } else {
                        value = Number(value);
                        if (value > 0) {
                          handleAgeChange("toAge", value);
                        } else if (value === 0) {
                          handleAgeChange("toAge", ""); // Start from 1 instead of 0
                        } else {
                          handleAgeChange("toAge", "");
                        }
                      }
                    }}
                    size="small"
                    InputProps={{
                      sx: {
                        height: "30px",
                        width: "48px",
                        fontFamily: "Brown, sans-serif",
                      },
                      notchedOutline: { borderColor: "#E5E5E5" },
                    }}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: "#E5E5E5",
                        },
                        "&:hover fieldset": {
                          borderColor: "#E5E5E5",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "#E5E5E5",
                        },
                      },
                    }}
                    oonKeyDown={(event) => {
                      // Prevent negative values and scientific notation ('e') from being typed
                      if (event.key === "-" || event.key === "e") {
                        event.preventDefault();
                      }
                      handleKeyPress(event);
                    }}
                    onDrop={(event) => {
                      const data = event.dataTransfer.getData("text");
                      if (
                        data.includes("e") ||
                        data.includes("-") ||
                        isNaN(Number(data))
                      ) {
                        event.preventDefault();
                      }
                    }}
                    onPaste={(event) => {
                      const data = event.clipboardData.getData("text");
                      if (
                        data.includes("e") ||
                        data.includes("-") ||
                        isNaN(Number(data))
                      ) {
                        event.preventDefault();
                      }
                    }}
                  />
                </Box>
              </Box>
              {(ageError.fromAge ||
                ageError.toAge ||
                ageError.rangeError ||
                ageError.emptyFieldError) && (
                <div className={styles.errorText}>
                  {ageError.rangeError
                    ? text?.filter_errors.from_shouldnt_exceed_to[lang]
                    : ageError.emptyFieldError
                    ? text?.filter_errors.field_cannot_be_empty[lang]
                    : text?.filter_errors.age_between_18_to_60[lang]}
                </div>
              )}
            </Box>
          </div>
          <div className={styles.filterCategory}>
            <div className={styles.filterTitle}>{text?.gender[lang]}</div>
            <div className={styles.filterOption}>
              <Checkbox
                icon={<CropSquareTwoToneIcon fontSize="medium" />}
                checked={stagedFilters.gender.includes("male")}
                onChange={() => handleCheckboxChange("male")}
                checkedIcon={
                  <img
                    src={imageAssetsData["checkedBoxIcon.png"]}
                    height={16}
                    width={16}
                    style={{ margin: 4 }}
                  />
                }
              />
              <label htmlFor="male">{text?.male[lang]}</label>
            </div>
            <div className={styles.filterOption}>
              <Checkbox
                icon={<CropSquareTwoToneIcon fontSize="medium" />}
                checked={stagedFilters.gender.includes("female")}
                onChange={() => handleCheckboxChange("female")}
                checkedIcon={
                  <img
                    src={imageAssetsData["checkedBoxIcon.png"]}
                    height={16}
                    width={16}
                    style={{ margin: 4 }}
                  />
                }
              />
              <label htmlFor="female">{text?.female[lang]}</label>
            </div>
            <div className={styles.filterOption}>
                <Checkbox
                  icon={<CropSquareTwoToneIcon fontSize="medium" />}
                  checked={stagedFilters.gender.includes("other")}
                  onChange={() => handleCheckboxChange("other")}
                  checkedIcon={
                    <img
                      src={imageAssetsData["checkedBoxIcon.png"]}
                      height={16}
                      width={16}
                      style={{ margin: 4 }}
                    />
                  }
                />
                <label htmlFor="non-binary">{text?.genders?.nonBinary[lang]}</label>
              </div>
          </div>

          <div className={styles.filterCategory}>
            <div className={styles.filterTitle}>{text?.status[lang]}</div>
            <div className={styles.filterOption}>
              <Checkbox
                icon={<CropSquareTwoToneIcon fontSize="medium" />}
                checked={stagedFilters.statuses.includes("Active")}
                onChange={() => handleStatusCheckboxChange("Active")}
                checkedIcon={
                  <img
                    src={imageAssetsData["checkedBoxIcon.png"]}
                    height={16}
                    width={16}
                    style={{ margin: 4 }}
                  />
                }
              />
              <label htmlFor="active">{text?.active[lang]}</label>
            </div>
            <div className={styles.filterOption}>
              <Checkbox
                icon={<CropSquareTwoToneIcon fontSize="medium" />}
                checked={stagedFilters.statuses.includes("Inactive")}
                onChange={() => handleStatusCheckboxChange("Inactive")}
                checkedIcon={
                  <img
                    src={imageAssetsData["checkedBoxIcon.png"]}
                    height={16}
                    width={16}
                    style={{ margin: 4 }}
                  />
                }
              />
              <label htmlFor="inactive">{text?.inactive[lang]}</label>
            </div>
          </div>
        </div>
        <button
          className={styles.applyFilterButton}
          onClick={handleApplyFilters}
          disabled={
            ageError.fromAge ||
            ageError.toAge ||
            ageError.rangeError ||
            ageError.emptyFieldError
          }
        >
          {text?.apply[lang]}
        </button>
      </Popover>
    </>
  );
};

export default RdpFilterModal;
