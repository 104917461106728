import React from "react";
import styles from "./RDPDashboard.module.css";
import PDPNav from "../RDPNav/RDPNav";
import RDPUserTable from "../RDPUserTable/RDPUserTable";

const RDPDashboard = () => {
  return (
    <div className={styles.dashboardContainer}>
      <div>
        <PDPNav />
        <RDPUserTable />
      </div>
    </div>
  );
};

export default RDPDashboard;
