import React, { createContext, useContext, useState } from "react";
import { jwtDecode } from "jwt-decode";

const AuthContext = createContext();
 
export const AuthProvider = ({ children }) => {
  const [authData, setAuthData] = useState(() => {
    const storedAuthData = localStorage.getItem("authData");
    return storedAuthData ? JSON.parse(storedAuthData) : null;
  });

  const [decodedData, setDecodedData] = useState(() => {
    const storedAuthData = localStorage.getItem("decodedData");
    return storedAuthData ? JSON.parse(storedAuthData) : null;
  });

  const [phoneInfo, setPhoneInfo] = useState(() => {
    const storedCountryCode = localStorage.getItem("countryCode");
    const storedPhoneNumber = localStorage.getItem("phoneNumber");
    return {
      countryCode: storedCountryCode || "",
      phoneNumber: storedPhoneNumber || "",
    };
  });
 
  const [selectedCodeHashed, setSelectedCodeHashed] = useState("");
 
  const [phoneNumHashed, setPhoneNumHashed] = useState("");
 
  // Admin state
  const [userCreateSuccess, setUserCreateSuccess] = useState("");
  const [isCreateUserModalOpen, setIsCreateUserModalOpen] = useState(false);
  
  const [loggedIn, setLoggedIn] = useState(!!localStorage.getItem("authData"));
  const [participantStatus, setParticipantStatus] = useState({});
 
  const loginInfo = (data) => {
    setAuthData(data);
    if (data) {
      const decodedResponse = jwtDecode(data?.data?.tokenresponse?.IdToken);
      setDecodedData(decodedResponse);
      localStorage.setItem("decodedData", JSON.stringify(decodedResponse));
    } else {
      setDecodedData(null);
      localStorage.setItem("decodedData", null);
    }
    localStorage.setItem("authData", data ? JSON.stringify(data) : null);
    setLoggedIn(true);
  };
 
  const [profileData, setProfileData] = useState(() => {
    const storedProfileData = localStorage.getItem("profileData");
    return storedProfileData ? JSON.parse(storedProfileData) : null;
  });
 
  const [profileStatus, setProfileStatus] = useState(() => {
    const storedProfileStatus = localStorage.getItem("profileStatus");
    return storedProfileStatus ? JSON.parse(storedProfileStatus) : null;
  });
 
  const [isLoginSuccessful, setIsLoginSuccessful] = useState(() => {
    const storedLoginSuccess = localStorage.getItem("loginSuccess");
    return storedLoginSuccess === "true";
  });
 
  const [isDiagnosticsEnabled, setIsDiagnosticsEnabled] = useState(() => {
    const storedFlag = localStorage.getItem("isDiagnosticsEnabled");
    return storedFlag === "true"; // Convert string to boolean
  });
 
  // Step 3: Create a function to update the diagnostic flag
  const setDiagnosticsEnabled = (isEnabled) => {
    setIsDiagnosticsEnabled(isEnabled); // Update state
    localStorage.setItem("isDiagnosticsEnabled", isEnabled.toString()); // Update localStorage
  };
 
  const setLoginSuccess = (success) => {
    setIsLoginSuccessful(success);
    localStorage.setItem("loginSuccess", success.toString());
  };
 
  const setAuth = (data) => {
    setAuthData(data);
    if (data) {
      const decodedResponse = jwtDecode(data?.data?.tokenresponse?.IdToken);
      setDecodedData(decodedResponse);
      localStorage.setItem("decodedData", JSON.stringify(decodedResponse));
    } else {
      setDecodedData(null);
      localStorage.setItem("decodedData", null);
    }
    localStorage.setItem("authData", JSON.stringify(data));
  };
 
  const setProfile = (data) => {
    setProfileData(data);
    localStorage.setItem("profileData", JSON.stringify(data));
  };
 
  const updateProfileStatus = (status) => {
    setProfileStatus(status);
    localStorage.setItem("profileStatus", JSON.stringify(status));
  };
 
  const setSelectedPhoneCodeHashed = (code) => {
    setSelectedCodeHashed(code);
  };
 
  const setPhoneNumberHashed = (number) => {
    setPhoneNumHashed(number);
  };
 
  //Super Admin Auth section ------------
 
  const [adminAuthData, setAdminAuthData] = useState(() => {
    const storedAdminAuthData = localStorage.getItem("adminAuthData");
    return storedAdminAuthData ? JSON.parse(storedAdminAuthData) : null;
  });
 
  const setAdminAuth = (data) => {
    setAdminAuthData(data); // Update state
    localStorage.setItem("adminAuthData", JSON.stringify(data)); // Update localStorage
  };
 
  const [selectedFilters, setSelectedFilters] = useState({
    roles: [], // Will store an array of selected role names like ["Raw Data Provider", "Urban Planner"]
    statuses: [],
    inputValue: "",
  });

  const [role, setRole] = useState(() => {
    const roleId = localStorage.getItem("roleId");
    return roleId ? JSON.parse(roleId) : null;
  });

  const setRoleId = (id) => {
    setRole(id);
    localStorage.setItem("roleId", JSON.stringify(id));
  };

 
  const [isAdminLoginSuccessful, setIsAdminLoginSuccessful] = useState(() => {
    const storedAdminLoginSuccess = localStorage.getItem("adminLoginSuccess");
    return storedAdminLoginSuccess === "true"; // Convert the stored string back to a boolean
  });
 
  const setAdminLoginSuccess = (success) => {
    setIsAdminLoginSuccessful(success); // Update the state
    localStorage.setItem("isReloadReq",true)
    localStorage.setItem("adminLoginSuccess", success.toString()); // Store the state in localStorage
  };
 
  const [isAdminOtpVerified, setIsAdminOtpVerified] = useState(() => {
    const storedOtpVerification = localStorage.getItem("adminOtpVerified");
    return storedOtpVerification === "true"; // Convert string to boolean
  });
 
  const setAdminOtpVerified = (verified) => {
    setIsAdminOtpVerified(verified); // Update state
    localStorage.setItem("adminOtpVerified", verified.toString()); // Update localStorage
  };
 
  const [adminInfo, setAdminInfoState] = useState(() => {
    const storedAdminInfo = localStorage.getItem('adminInfo');
    return storedAdminInfo ? JSON.parse(storedAdminInfo) : { username: '', role: '' };
  });
 
  const setAdminInfo = (info) => {
    setAdminInfoState(info); // Update state with the new info object
    localStorage.setItem('adminInfo', JSON.stringify(info)); // Store the object as a JSON string in localStorage
  };
 
  const signOut = () => {
    setAuthData(null);
    setProfileData(null);
    setPhoneInfo({ countryCode: "", phoneNumber: "" });
    setSelectedCodeHashed("");
    setPhoneNumHashed("");
    setRoleId("");
    setLoggedIn(false);
    setIsLoginSuccessful(false);
    setIsDiagnosticsEnabled(false);
    setDecodedData(null);
    localStorage.removeItem("isDiagnosticsEnabled");
    localStorage.removeItem("authData");
    localStorage.removeItem("profileData");
    localStorage.removeItem("countryCode");
    localStorage.removeItem("phoneNumber");
    localStorage.removeItem("otp_session");
    localStorage.removeItem("profileStatus");
    localStorage.removeItem("cc");
    localStorage.removeItem("pn");
    localStorage.removeItem("loginSuccess");
    localStorage.removeItem("decodedData")
    localStorage.removeItem("localImageAssetsData")
    // localStorage.clear();
    localStorage.setItem("isReloadReq",true)
 
    // admin data clear from local storage ---------------------
    setAdminAuthData(null); // Clear admin auth data state
    localStorage.removeItem("adminAuthData");
    setIsAdminLoginSuccessful(false);
    localStorage.removeItem("adminLoginSuccess");
    setIsAdminOtpVerified(false);
    localStorage.removeItem("adminOtpVerified");
    setAdminInfo({ username: '', role: '' }); // Reset admin info state
    localStorage.removeItem('adminInfo');
  };
 
  return (
    <AuthContext.Provider
      value={{
        authData,
        decodedData,
        profileData,
        setAuth,
        setProfile,
        signOut,
        loggedIn,
        setLoggedIn,
        loginInfo,
        updateProfileStatus,
        profileStatus,
        phoneInfo,
        selectedCodeHashed,
        setSelectedPhoneCodeHashed,
        phoneNumHashed,
        setPhoneNumberHashed,
        setPhoneInfo,
        isLoginSuccessful,
        setLoginSuccess,
        isDiagnosticsEnabled,
        setDiagnosticsEnabled,
        role,
        setRole,
        setRoleId,
 
        //ParticipantStatus
        participantStatus,
        setParticipantStatus,
 
        // Admin data pass globally----------
        adminAuthData,
        setAdminAuth,
        selectedFilters,
        setSelectedFilters,
        isAdminLoginSuccessful,
        setAdminLoginSuccess,
        isAdminOtpVerified,
        setAdminOtpVerified,
        userCreateSuccess,
        setUserCreateSuccess,
        isCreateUserModalOpen,
        setIsCreateUserModalOpen,
        adminInfo,
        setAdminInfo,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
 
export const useAuth = () => useContext(AuthContext);