import React, { useState, useEffect } from "react";
import styles from "./ParticipantProfileCreation.module.css";
import { useAuth } from "../../../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import { RDP_DASHBOARD } from "../../../apiRoutes/rdpParticipantRoutes/rdpParticipantRoutes";
import { PARTICIPANT_PROFILE_VIEW } from "../../../apiRoutes/participantRoutes/participantsRoutes";
import constants from "../../../../constants";
import { text } from "../../../../constant/text";
import { lang } from "../../../../constant/Var";
import GreenTick from "../../../../assets/svgicons/GreenTick";

const ParticipantProfileCreation = () => {
  const { isLoginSuccessful, setLoginSuccess, role } = useAuth();
  const [message, setMessage] = useState(text?.sign_in_successful[lang]);
  const navigate = useNavigate();

  const preventBack = () => {
    window.history.pushState(null, "", window.location.href);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoginSuccess(true);
    }, 3000);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (isLoginSuccessful) {
      localStorage.setItem("isReloadReq",true)
      if (role === constants.RDP) {
        navigate(RDP_DASHBOARD);
      } else {
        navigate(PARTICIPANT_PROFILE_VIEW);
      }
    }
  }, [isLoginSuccessful]);

  useEffect(() => {
    window.history.pushState(null, "", window.location.href);
    window.addEventListener("popstate", preventBack);

    return () => {
      window.removeEventListener("popstate", preventBack);
    };
  }, []);

  return (
    <div className={styles.participantProfileCreation}>
      <div className={styles.profileContainer}>
        <div className={styles.profileCreation}>
          <GreenTick/>
        </div>
        <h1 className={styles.profileText}>{message}</h1>
        <div className={styles.buttonContainer}></div>
      </div>
    </div>
  );
};

export default ParticipantProfileCreation;
