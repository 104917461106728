import React, { useState, useEffect } from "react";
import styles from "./ParticipantProfileViewScreen.module.css";
import "../../../../font.css";
import ParticipantSetting from "../ParticipantSetting/ParticipantSetting";
import { useLocation, useNavigate } from "react-router-dom";
import {
  getParticipantStatus,
  logoutProfile,
  participantProfileView,
  refreshToken,
} from "../../../apiRoutes/participantApi/participant-api";
import axios from "axios";
import {
  PARTICIPANT,
  PARTICIPANT_PROFILE_VIEW,
} from "../../../apiRoutes/participantRoutes/participantsRoutes";
import { useAuth } from "../../../../context/AuthContext";
import neomLogo2 from "../../../../assets/images/neomUpdatedLogo.png";
import ParticipantProfileUpdateScreen from "../ParticiopantProfileUpdate/ParticipantProfileUpdateScreen";
import ParticipantDiagnostic from "../ParticipantDiagnostic/ParticipantDiagnostic";
import StatusProgress from "./StatucProgress/StatusProgress";
import ProfileNavigation from "./ProfileNavigation/ProfileNavigation";
import { RDP_DASHBOARD } from "../../../apiRoutes/rdpParticipantRoutes/rdpParticipantRoutes";
import constants from "../../../../constants";
import {
  ADMIN_DASHBOARD,
  ADMIN_SIMULATIONS,
} from "../../../apiRoutes/adminRoutes/adminRoutes";
import { useImageAssets } from "../../../../context/ImageAssetsContext";
import { lang, refershTokenTime } from "../../../../constant/Var";
import CrossIcon from "../../../../assets/svgicons/CrossIcon";
import ThreeDotsLoader from "../../../../sharedComponents/ThreeDotsLoader";
import { text } from "../../../../constant/text";
import Banner from "../../../../sharedComponents/Banner/Banner";

const ParticipantProfileViewScreen = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const {
    authData,
    signOut,
    profileStatus,
    setAuth,
    participantStatus,
    setParticipantStatus,
    decodedData,
    role,
    adminInfo,
  } = useAuth();
  const accessToken = authData?.data?.tokenresponse?.AccessToken;
  const idToken =
    authData?.data?.tokenresponse?.IdToken || localStorage.getItem("id_token");
  const [activeIcon, setActiveIcon] = useState("home");

  const [profileUpdated, setProfileUpdated] = useState(false);

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { imageAssetsData } = useImageAssets();
  const [loading, setLoading] = useState(false);
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  const [networkError, setNetworkError] = useState(false); // state for network error

  const handleProfileUpdateSuccess = async () => {
    try {
      setProfileUpdated(true);
    } catch (error) {
      setNetworkError(true); // set network error state
      console.error("Error updating profile:", error);
    }
  };

  const handleStatusProgress = () => {
    fetchParticipantStatus();
  };

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const handleResize = () => {
    setWindowWidth(window.innerWidth);

    if (window.innerWidth > 768 && isMenuOpen) {
      setIsMenuOpen(false);
    }
  };

  const [participantProfileData, setParticipantProfileData] = useState({
    age: "",
    gender: "",
  });
  useEffect(() => {
    const fetchData = async () => {
      const idToken =
        authData?.data?.tokenresponse?.IdToken ||
        localStorage.getItem("id_token");
      const accessToken =
        authData?.data?.tokenresponse?.AccessToken ||
        localStorage.getItem("access_token");

      if (!accessToken || !idToken) {
        console.error("Tokens are not available");
        return;
      }

      const config = {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          id_token: idToken,
        },
      };

      try {
        const response = await axios.get(participantProfileView, config);
        if (response.data) {
          setParticipantProfileData(response.data.data);
        }
      } catch (error) {
        if (
          error.response?.data?.message.includes(
            constants.ACCESS_TOKEN_IS_INVALID
          )
        ) {
          signOut();
        } else {
          if (error.message.includes("Network Error")) {
            setNetworkError(true)
          } else {
            console.error("Error fetching participants data:", error);
          }
        }
      } finally {
      }
    };

    fetchData();
  }, [activeIcon]);

  // Effect to listen to window resize events
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    // Call the function immediately to set the initial value
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, [isMenuOpen]); // Include isMenuOpen in the dependency array

  // Conditional rendering based on window width
  const hideProfileNavigation = windowWidth <= 768;

  const VIEW = {
    TERMS: "TERMS",
    SETTINGS: "SETTINGS",
  };

  const preventBack = () => {
    window.history.pushState(null, "", window.location.href);
  };

  useEffect(() => {
    window.history.pushState(null, "", window.location.href);
    window.addEventListener("popstate", preventBack);

    return () => {
      window.removeEventListener("popstate", preventBack);
    };
  }, []);

  const handleLogoutClick = () => {
    const config = {
      method: "post",
      url: logoutProfile,
      headers: {
        access_token: accessToken,
      },
    };

    axios
      .request(config)
      .then((response) => {
        localStorage.removeItem("age");
        localStorage.removeItem("gender");
        localStorage.removeItem("verifying");
        localStorage.removeItem("buttondisabled");
        navigate(PARTICIPANT);
        signOut();
      })
      .catch((error) => {
        if (error.message.includes("Network Error")) {
          setNetworkError(true)
        } else {
          console.error("Error Loggingout the profile:", error.message);
          signOut();
        }
      })
      .finally(() => {});
  };

  const currentPath = "/participant-profile-view";
  useEffect(() => {
    if (window.location.pathname !== currentPath) {
      navigate(currentPath);
    }
  }, [location.pathname, navigate, currentPath]);

  const fetchParticipantStatus = async () => {
    const idTok = idToken || localStorage.getItem("id_token");
    const accessTok = accessToken || localStorage.getItem("access_token");

    if (!accessTok || !idTok) {
      console.error("Tokens are not available");
      return;
    }

    const statusConfig = {
      method: "get",
      url: getParticipantStatus, // Make sure refreshToken is a defined variable with the API URL
      headers: {
        Authorization: `Bearer ${accessTok}`,
        id_token: idTok,
      },
    };

    try {
      const response = await axios(statusConfig);
      if (response.data && response.data.status) {
        setParticipantStatus(response.data.status);
      }
    } catch (error) {
      if (error.message.includes("Network Error")) {
        setNetworkError(true)
      } else {
        console.error("There was an error!", error);
      }
    }
  };

  //token refresh
  useEffect(() => {
    const config = {
      method: "post",
      url: refreshToken, // Make sure refreshToken is a defined variable with the API URL
      headers: {
        refresh_token: authData.data.tokenresponse.RefreshToken,
      },
    };

    // Function to make the API call and update authData
    const fetchData = async () => {
      try {
        const response = await axios(config);

        if (response.data && response.data.success) {
          const updatedAuthData = {
            ...authData,
            data: {
              ...authData.data,
              tokenresponse: {
                ...authData.data.tokenresponse,
                AccessToken: response.data.data.AccessToken,
                IdToken: response.data.data.IdToken,
              },
            },
          };
          setAuth(updatedAuthData);
        }
      } catch (error) {
        if (
          error.response?.data.message.includes(
            constants.FAILED_TO_GENERATE_TOKEN
          )
        ) {
          signOut();
        } else if (error.message?.includes("Network Error")) {
            setNetworkError(true)
        } else {
            console.error("There was an error!", error);
        }
      }
    };

    fetchData();
    fetchParticipantStatus();
    const intervalId = setInterval(fetchData, refershTokenTime);
    return () => clearInterval(intervalId);
  }, [activeIcon]);

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  };

  useEffect(() => {
    if (role === constants.RDP) {
      navigate(RDP_DASHBOARD);
    } else if (role === constants.PARTICIPANT) {
      navigate(PARTICIPANT_PROFILE_VIEW);
    } else if (adminInfo.role === "Admin") {
      navigate(ADMIN_DASHBOARD);
    } else if (adminInfo.role === constants.URBAN_PLANNER) {
      navigate(ADMIN_SIMULATIONS);
    }
  }, []);

  // Set loading state to true and initiate the fetchParticipantStatus after 3 seconds delay
  useEffect(() => {
    setLoading(true);
    const timer = setTimeout(() => {
      fetchParticipantStatus(); // Call the function after 3 seconds
      setLoading(false);
    }, 3000);
    return () => clearTimeout(timer);
  }, []);

  const handleRefresh = () => {
    window.location.reload();
  };
  
  // Conditional rendering for the diagnostic header
  const getHeaderText = () => {
    if (loading) {
      return "";
    } else if (activeIcon === "settings") {
      return text?.settings[lang];
    } else if (
      !participantStatus["Profile Information"] &&
      (!profileStatus || !authData.data.profile_status) &&
      participantProfileData.gender === null
    ) {
      return text?.update_profile[lang];
    } else {
      return text?.diagnostics[lang];
    }
  };

  return (
    <div className={styles.profileViewContainer}>
      {networkError && <Banner message={text?.validations?.something_went_wrong_refresh[lang]} onRefresh={handleRefresh} action={text?.refresh[lang]}/>}
      <div
        className={styles.imageSection}
        style={{
          backgroundImage: `url(${imageAssetsData["profileViewBackgroundImg.png"]})`,
        }}
      >
        <div className={styles.logoNeom}>
          <img
            src={neomLogo2}
            alt="logo"
            className={styles.logoReponsive}
            draggable="false"
          />
        </div>
        <div className={isMenuOpen ? styles.crossButton : styles.hamburgerMenu} onClick={toggleMenu}>
          {isMenuOpen ? (
            <CrossIcon className={styles.crossIcon} />
          ) : (
            <>
              <div className={styles.bar}></div>
              <div className={styles.bar}></div>
              <div className={styles.bar}></div>
            </>
          )}
        </div>
        <div
          className={`${styles.drawer} ${isMenuOpen ? styles.drawerOpen : ""}`}
        >
          <ProfileNavigation
            activeIcon={activeIcon}
            setActiveIcon={setActiveIcon}
            handleLogoutClick={handleLogoutClick}
            toggleMenu={toggleMenu}
          />
        </div>
        <div className={styles.profileWelcomeText}>
          <span>
            {text?.hey[lang]}, {capitalizeFirstLetter(decodedData?.name)}
          </span>
          <span>{text?.welcome_to_the_VH[lang]}</span>
        </div>
      </div>

      <StatusProgress loading={loading} participantStatus={participantStatus} activeIcon={activeIcon} />

      <div className={styles.contentSection}>
        {hideProfileNavigation ? null : (
          <ProfileNavigation
            activeIcon={activeIcon}
            setActiveIcon={setActiveIcon}
            handleLogoutClick={handleLogoutClick}
            toggleMenu={toggleMenu}
          />
        )}
        {/* Conditional rendering for the diagnostic header */}
        <div className={styles.diagnosticHeader}>
          {getHeaderText()}
        </div>
        <div className={styles.subSection}>
          {activeIcon === "home" &&
            (loading ? (
              <div className={styles.overlay}>
                <ThreeDotsLoader color="#000" />
              </div>
            ) : !participantStatus["Profile Information"] &&
              (!profileStatus || !authData.data.profile_status) && participantProfileData.gender === null? (
              <ParticipantProfileUpdateScreen
                onProfileUpdateSuccess={handleProfileUpdateSuccess}
                onPrticipantDetailsUpdate={handleStatusProgress}
                setNetworkError={setNetworkError}
              />
            ) : participantProfileData.gender !== ''? (
              <ParticipantDiagnostic />
            ) : <></>
            )}

          {activeIcon === "settings" && <ParticipantSetting />}
        </div>
      </div>
    </div>
  );
};

export default ParticipantProfileViewScreen;
